import React from "react";
import { Typography, Tooltip, Button, Tag, Card, Divider, Alert } from "antd";
import Support from "../../../Components/Common/ProductContentSupport";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const ProductExternal = ({ item, index, length }) => {
  // console.log("item", item);
  const externalType = item?.external_product_content_info?.external_type;

  const externalContentType = () => {
    if (!item) return "";

    if (externalType === "website") return "Conteúdo em página externa";
    if (externalType === "membership") return "Conteúdo em área de membro externa";
    if (externalType === "email")
      return "Conteúdo será entregue via e-mail pelo produtor até um máximo de 24 horas após a compra.";
    if (externalType === "other")
      return "Conteúdo/Produto será entregue pelo produtor até um máximo de 24 horas após a compra.";
  };
  return (
    <>
      <Card
        size="small"
        type="inner"
        title={
          <>
            <strong>Produto: </strong>
            <span style={{ fontWeight: 400 }}>{item.product_name}</span>
          </>
        }
        style={{ marginBottom: 7 }}
      >
        {item?.refunded === true ? (
          <>
            <Alert
              message="Produto indisponível"
              description="Este produto foi reembolsado ou está em processo de reembolso."
              type="error"
              showIcon
            />
          </>
        ) : (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <Text type="secondary" mark>
                <em>{externalContentType()}</em>
              </Text>
            </div>
            <div>
              {externalType === "website" || externalType === "membership" ? (
                <Tooltip title="Ir para o conteúdo" placement="topRight">
                  <Link to={`${item.external_product_content_info.url}`} target="_blank">
                    <Button type="primary" className="mr-2">
                      Ir para o conteúdo <RightOutlined />
                    </Button>
                  </Link>
                </Tooltip>
              ) : (
                <>
                  {externalType === "email" && <Tag color="geekblue">Aguarda instruções do produtor</Tag>}
                  {externalType === "other" && <Tag color="gold">Aguarda instruções do produtor</Tag>}
                </>
              )}
            </div>
          </div>
        )}
        <Support data={item.support} />
      </Card>
      {index !== length - 1 && <Divider dashed style={{ borderColor: "#838383" }} />}
    </>
  );
};

export default ProductExternal;
