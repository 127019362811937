import React, { useState } from "react";
import { Divider, Modal, Button } from "antd";
import PurchaseTerms from "./PurchaseTerms";
import { Link } from "react-router-dom";
import AppVersion from "Components/Shared/AppVersion";
const LegalAndSupport = ({ data, productData, isMobile = false }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="legal-container">
        <Divider dashed />
        <div className="legal">
          Estou ciente de que a SalesPark está a processar este pedido a serviço de{" "}
          <strong>{data?.producer?.name}</strong>, concordo com os{" "}
          <span className="span-link" onClick={() => showModal()}>
            Termos de Compra
          </span>
          {", "}
          confirmo que li e entendi todos os{" "}
          <Link className="span-link" to={"https://salespark.io/redirect/?u=general-terms-of-use"} target="_blank">
            Termos e Políticas
          </Link>{" "}
          e declaro que sou maior de idade ou estou autorizado e acompanhado por um tutor legal.
          {isMobile && (
            <div style={{ textAlign: "center" }}>
              <AppVersion align={"center"} />
            </div>
          )}
        </div>
      </div>
      <Modal
        width={900}
        className="purchase-terms-modal"
        closable
        title="Termos de Compra"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <Button type="primary" onClick={() => handleOk()}>
            Fechar
          </Button>
        }
      >
        <PurchaseTerms data={data} productData={productData} />
      </Modal>
    </>
  );
};

export default LegalAndSupport;
