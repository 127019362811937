import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { action_meta_events_post } from "../api/actions";

let metalClientPixel = undefined;
let ga4ClientPixel = undefined;

/****************************************************
 * ##: Init Meta Pixel
 * @param {Object} payload
 * @param {String} meta_pixel_id
 * History:
 * 27-01-2024: Created
 ****************************************************/
const initMetaPixel = (payload, meta_pixel_id = undefined) => {
  if (meta_pixel_id === undefined) {
    return;
  }

  const advancedMatching = { em: payload.email }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  // External Pixel
  ReactPixel.init(meta_pixel_id, advancedMatching, options);
  metalClientPixel = ReactPixel;
  return ReactPixel;

  //https://developers.facebook.com/docs/meta-pixel/reference#standard-events
};

/****************************************************
 * ##: Init GA4 Pixel
 * https://developers.google.com/tag-platform/gtagjs/reference/events
 * @param {Object} payload
 * @param {String} ga4_pixel_id
 * History:
 * 29-01-2023: Created
 * 27-01-2024: Refactored and add GA4
 ****************************************************/
const initGA4Pixel = (ga4_pixel_id = undefined) => {
  if (ga4_pixel_id === undefined) {
    return;
  }

  ReactGA.initialize(ga4_pixel_id);
  ga4ClientPixel = ReactGA;
  return ReactGA;
};

/****************************************************
 * ##: Event Page View
 * @param {Object} payload
 * History:
 * 29-01-2023: Created
 * 27-01-2024: Refactored and add GA4
 ****************************************************/
export const pixelEventPageView = (payload) => {
  const pixel = getProducerPixel(payload);
  if (pixel?.meta?.pixel_id === undefined && pixel?.ga4?.pixel_id === undefined) return;

  // Meta ***************************************************************************
  if (pixel?.meta !== undefined) {
    if (pixel.config_type === "conversionAPI") {
      metaAPIEvents(payload, "PageView");
    } else {
      const _reactPixel = metalClientPixel || initMetaPixel(payload, pixel.meta.pixel_id);
      _reactPixel.track("PageView");
    }
  }

  // GA4 ***************************************************************************
  if (pixel?.ga4 !== undefined) {
    ga4Events(pixel?.ga4?.pixel_id, payload, "page_view");
  }
};

/****************************************************
 * ##: Event Initiate Checkout
 * @param {Object} payload
 * History:
 * 29-01-2023: Created
 * 27-01-2024: Refactored and add GA4
 ****************************************************/
export const pixelEventInitiateCheckout = (payload) => {
  const pixel = getProducerPixel(payload);
  if (pixel?.meta?.pixel_id === undefined && pixel?.ga4?.pixel_id === undefined) return;

  // Meta ***************************************************************************
  if (pixel?.meta !== undefined) {
    if (pixel.config_type === "conversionAPI") {
      metaAPIEvents(payload, "InitiateCheckout");
    } else {
      metaEvents(pixel.meta.pixel_id, payload, "InitiateCheckout");
    }
  }

  // GA4 ***************************************************************************
  if (pixel?.ga4 !== undefined) {
    ga4Events(pixel?.ga4?.pixel_id, payload, "begin_checkout");
  }
};

/****************************************************
 * ##: Event Add Payment Info
 * @param {Object} payload
 * History:
 * 29-01-2023: Created
 * 27-01-2024: Refactored and add GA4
 ****************************************************/
export const pixelEventAddPaymentInfo = (payload) => {
  const pixel = getProducerPixel(payload);
  if (pixel?.meta?.pixel_id === undefined && pixel?.ga4?.pixel_id === undefined) return;

  // Meta ***************************************************************************
  if (pixel?.meta !== undefined) {
    if (pixel.config_type === "conversionAPI") {
      metaAPIEvents(payload, "AddPaymentInfo");
    } else {
      metaEvents(pixel.meta.pixel_id, payload, "AddPaymentInfo");
    }
  }

  // GA4 ***************************************************************************
  if (pixel?.ga4 !== undefined) {
    ga4Events(pixel?.ga4?.pixel_id, payload, "add_payment_info");
  }
};

/****************************************************
 * ##: Event Purchase
 * @param {Object} payload
 * History:
 * 29-01-2023: Created
 * 27-01-2024: Refactored and add GA4
 ****************************************************/
export const pixelEventPurchase = (payload) => {
  const pixel = getProducerPixel(payload);
  if (pixel?.meta?.pixel_id === undefined && pixel?.ga4?.pixel_id === undefined) return;

  if (pixel?.meta !== undefined) {
    if (pixel.config_type === "conversionAPI") {
      metaAPIEvents(payload, "Purchase");
    } else {
      metaEvents(pixel.meta.pixel_id, payload, "Purchase");
    }
  }

  // GA4 ***************************************************************************
  if (pixel?.ga4 !== undefined) {
    ga4Events(pixel?.ga4?.pixel_id, payload, "purchase");
  }
};

/****************************************************
 * ##: Meta Conversion API Event
 * @param {Object} payload
 * History:
 * 29-01-2023: Created
 * 28-01-2024: Updated
 ****************************************************/
const metaAPIEvents = (payload, event) => {
  return new Promise((resolve, reject) => {
    try {
      let total = payload?.purchase_products.reduce((a, b) => a + b.price, 0);
      total = parseFloat(total.toFixed(2));

      let data = {
        email: payload.identification.email,
        url: window.location.href,
        product_id: payload.main_product,
        pixel_id: payload.meta_pixel.pixel_id,
        value: total,
        event: event,
      };

      if (process.env?.ENVIRONMENT?.toLowerCase() !== "production") {
        data.testing = "TEST24612";
      }

      action_meta_events_post(data)
        .then((res) => resolve(res))
        .catch((err) => {
          reject({ ...err });
        });

      // Error handling
    } catch (error) {
      console.log("metaConversionAPIEvent", error);
      reject(error);
    }
  });
};

/****************************************************
 * ##: Meta Events
 * @param {Object} payload
 * History:
 * 28-01-2024: Created
 ****************************************************/
const metaEvents = (pixel_id, payload, event) => {
  return new Promise((resolve, reject) => {
    try {
      let total = payload?.purchase_products.reduce((a, b) => a + b.price, 0);
      total = parseFloat(total.toFixed(2));

      //console.log("metaEvents", payload);
      let eventData = {};

      if (event === "PageView") {
        eventData = {
          page_location: window.location.href,
        };
      }

      if (event === "InitiateCheckout") {
        eventData = {
          contents: payload.purchase_products.map((product) => ({
            id: product.product_id,
            name: product.name,
            price: product.price,
          })),
        };
      }

      if (event === "AddPaymentInfo") {
        eventData = {
          payment_type: payload?.payment_method?.method_id,
        };
      }

      if (event === "Purchase") {
        eventData = {
          transaction_id: payload.purchase_id,
          contents: payload.purchase_products.map((product) => ({
            id: product.product_id,
            name: product.name,
            price: product.price,
          })),
        };
      }
      let data = {
        currency: "EUR",
        value: total,
        coupon: payload?.coupon?.coupon_code,
        url: window.location.href,
        ...eventData,
      };

      if (process.env?.ENVIRONMENT?.toLowerCase() !== "production") {
        data.env = process.env?.ENVIRONMENT?.toLowerCase();
      }

      const _pixel = metalClientPixel || initMetaPixel(payload, pixel_id);
      _pixel.track(event, data);
      resolve();

      // Error handling
    } catch (error) {
      //console.log("metaEvents", error);
      reject(error);
    }
  });
};

/****************************************************
 * ##: GA4 Events
 * @param {Object} payload
 * History:
 * 28-01-2024: Created
 ****************************************************/
const ga4Events = (pixel_id, payload, event) => {
  return new Promise((resolve, reject) => {
    try {
      let total = payload?.purchase_products.reduce((a, b) => a + b.price, 0);
      total = parseFloat(total.toFixed(2));

      let eventData = {};

      if (event === "page_view") {
        eventData = {
          page_location: window.location.href,
        };
      }

      if (event === "begin_checkout") {
        eventData = {
          items: payload.purchase_products.map((product) => ({
            item_id: product.product_id,
            item_name: product.name,
            price: product.price,
          })),
        };
      }

      if (event === "add_payment_info") {
        eventData = {
          payment_type: payload?.payment_method?.method_id,
        };
      }

      if (event === "purchase") {
        eventData = {
          transaction_id: payload.purchase_id,
          items: payload.purchase_products.map((product) => ({
            item_id: product.product_id,
            item_name: product.name,
            price: product.price,
          })),
        };
      }
      let data = {
        currency: "EUR",
        value: total,
        coupon: payload?.coupon?.coupon_code,
        url: window.location.href,
        ...eventData,
      };

      if (process.env?.ENVIRONMENT?.toLowerCase() !== "production") {
        data.env = process.env?.ENVIRONMENT?.toLowerCase();
      }

      const _pixel = ga4ClientPixel || initGA4Pixel(pixel_id);
      _pixel.event(event, data);
      resolve();

      // Error handling
    } catch (error) {
      console.log("ga4Events", error);
      reject(error);
    }
  });
};

/****************************************************
 * ##: Get Meta Pixel
 * @param {Object} payload
 * History:
 * 29-01-2023: Created
 * 27-01-2024: Refactored and add GA4
 ****************************************************/
const getMetaPixel = (payload) => {
  try {
    if (payload?.meta_pixel !== undefined && payload?.meta_pixel !== null) {
      if (
        payload?.meta_pixel?.active === true &&
        payload?.meta_pixel?.pixel_id !== undefined &&
        payload?.meta_pixel?.pixel_id !== null
      ) {
        return { config_type: payload?.meta_pixel?.config_type, pixel_id: payload?.meta_pixel?.pixel_id };
      }
      return undefined;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

/****************************************************
 * ##: Get GA4 Pixel
 * @param {Object} payload
 * History:
 * 29-01-2023: Created
 * 27-01-2024: Refactored and add GA4
 ****************************************************/
const getGA4Pixel = (payload) => {
  try {
    if (payload?.ga4_pixel !== undefined && payload?.ga4_pixel !== null) {
      if (
        payload?.ga4_pixel?.active === true &&
        payload?.ga4_pixel?.pixel_id !== undefined &&
        payload?.ga4_pixel?.pixel_id !== null
      ) {
        return { pixel_id: payload?.ga4_pixel?.pixel_id };
      }
      return undefined;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

/****************************************************
 * ##: Get Producer Pixel
 * @param {Object} payload
 * History:
 * 29-01-2023: Created
 * 27-01-2024: Refactored and add GA4
 ****************************************************/
const getProducerPixel = (payload) => {
  try {
    const metaPixel = getMetaPixel(payload);
    const ga4Pixel = getGA4Pixel(payload);

    return {
      meta: metaPixel,
      ga4: ga4Pixel,
    };
  } catch (e) {
    return undefined;
  }
};
