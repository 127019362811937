import AppVersion from "../Shared/AppVersion";
import Company from "../Shared/Company";
const Footer = () => {
  return (
    <div className="footer">
      <Company />
      <AppVersion />
    </div>
  );
};

export default Footer;
