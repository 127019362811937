import { Alert, Button } from "antd";

const ErrorLoadingPayPal = ({ getPayPalClientId, setSelectPaymentMethod, paypalClientId }) => {
  return (
    <div className="payment-error">
      <div className="error">
        <div className="alerts">
          {paypalClientId === undefined && (
            <Alert
              message="Oops, ocorreu um erro ao ligar com a PayPal."
              description="Não foi possível efetuar o pedido ao PayPal. Tenta novamente dentro de alguns segundos."
              type="error"
              showIcon
            />
          )}
          {paypalClientId !== undefined && (
            <Alert
              message="Oops, ocorreu um erro ao efetuar o pedido ao PayPal."
              description="Não foi possível efetuar o pedido ao PayPal. Tenta novamente dentro de alguns segundos."
              type="error"
              showIcon
            />
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <Button type="primary" onClick={() => getPayPalClientId()}>
            Tentar novamente
          </Button>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button type="link" onClick={() => setSelectPaymentMethod({ visible: true })}>
            Selecionar outro método de pagamento
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorLoadingPayPal;
