import React, { useRef } from "react";
import { Button, Alert } from "antd";
import { RightOutlined, ArrowDownOutlined } from "@ant-design/icons";
import SelectPriceOption from "./SelectPriceOption";
import SelectPaymentMethod from "./SelectPaymentMethod";
import OrderBumps from "./OrderBumps";
import { NotNullUndefined, paymentMethodValid } from "../../../utils/helper";
//import useMediaQuery from "use-media-antd-query";
import { BuyerInvoiceInfo } from "utils/helper";
import { useInView } from "react-intersection-observer";

const isWidget = window?.location?.hash?.substr(1) === "widget";
const Options = ({ productData, data, actionHandler, step }) => {
  const ref = useRef(null);
 // const colSize = useMediaQuery();
  const validatePaymentMethod = paymentMethodValid(data);
  // const _validatePaymentAction = () => {
  //   return paymentMethodValid(data);
  // };

  const { ref: nextButtonRef, entry: isNextButtonVisible } = useInView({
    /* Optional options */
    threshold: 1,
    //rootMargin: "20px",
    trackVisibility: true,
    delay: 100,
  });

  return (
    <div className="options-container" ref={ref}>
      <SelectPriceOption productData={productData} data={data} actionHandler={actionHandler} />

      <SelectPaymentMethod data={data} actionHandler={actionHandler} />

      {NotNullUndefined(data?.payment_method) && NotNullUndefined(data?.price_option) && (
        <>
          {/* {colSize === "xs" && (
            <div className="flex-center">
              <Button
                type="link"
                onClick={() => {
                  if (ref.current) {
                    ref.current?.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                <ArrowDownOutlined /> Ir para o pagamento <ArrowDownOutlined />
              </Button>
            </div>
          )} */}
          <OrderBumps step={step} productData={productData} data={data} actionHandler={actionHandler} />
          {!validatePaymentMethod.status && (
            <div className="button" style={{ marginTop: 40, display: "flex", justifyContent: "center" }}>
              <Alert
                message="Método de pagamento indisponível."
                description={validatePaymentMethod.data}
                type="error"
                showIcon
              />
            </div>
          )}
          <div className="button" style={{ marginTop: 40, display: "flex", justifyContent: "end" }}>
            <Button
              ref={nextButtonRef}
              disabled={!validatePaymentMethod.status}
              size="large"
              type="primary"
              block
              htmlType="submit"
              className="submit-button"
              onClick={() => {
                actionHandler("options");
                ref.current?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Efetuar o pagamento <RightOutlined />
            </Button>
          </div>

          <div className="billing-data-notice">
            <BuyerInvoiceInfo isWidget={isWidget} data={data?.thankyou_page} />
          </div>

          {isNextButtonVisible?.isVisible === false && (
            <div className="nextButtonFixed">
              <Button
                disabled={!validatePaymentMethod.status}
                type="primary"
                htmlType="submit"
                className="submit-button-smaller"
                onClick={() => {
                  actionHandler("options");
                  ref.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Efetuar o pagamento <RightOutlined />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Options;
