import React, { useState, useEffect } from "react";
import { Button, notification, Alert, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { sales_installments_payment_request_create_charge_post } from "../../api/sales";
import { purchaseProductsTotal, formatCurrency, formatMBReference } from "utils/helper";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../Components/Shared/CheckoutForm";
import { payment_stripe_publishable_key_get, stripeV4CreatePaymentSession } from "api/payments";
import moment from "moment-timezone";

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 **********************************************************************************************/

const ChargeCreditCard = ({ data, initCheckPayment }) => {
  const [loading, setLoading] = useState(false);
  const [waitForPayment, setWaitForPayment] = useState(false);
  const [paymentDataResponse, setPaymentDataResponse] = useState(data?.payment_method_details);
  const [errorFetching, setErrorFetching] = useState(false);
  const [stripeData, setStripeData] = useState({ client_secret: "", intent_id: "", payment_unique_id: "" });
  const [stripePromise, setStripePromise] = useState(null);
  const [publishableKey, setPublishableKey] = useState("");
  const [stripeSessionId, setStripeSessionId] = useState(null);

  useEffect(() => {
    prepareCheckout();
  }, []);

  const createCharge = async () => {
    createChargeAction({ ...data, new_payment_method: "CREDITCARD" });
  };

  const createChargeAction = async (payload) => {
    try {
      setLoading(true);
      setWaitForPayment(false);
      const response = await dbCreateCharge(payload);

      if (!response.status) {
        notification.error({
          message: "Erro",
          description: "Não foi possível criar o pagamento.",
        });
        setErrorFetching(true);

        setLoading(false);

        return;
      }
      setPaymentDataResponse({ ...response.data });
      initCheckPayment({ ...response.data, method_id: "CREDITCARD" });
      setErrorFetching(false);
      setWaitForPayment(true);
      setLoading(false);

      // Error handling
    } catch (error) {
      //  console.log("error", error);
      setErrorFetching(true);

      setLoading(false);
    }
  };

  const dbCreateCharge = (payload) => {
    return new Promise((resolve) => {
      try {
        sales_installments_payment_request_create_charge_post(payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const prepareCheckout = async () => {
    setLoading(true);
    setErrorFetching(false);
    // Get Stripe publishable key
    const pub_key_response = await getPublishableKey("none");
    // Check if there is an error
    if (!pub_key_response.status) {
      setErrorFetching(true);
      setLoading(false);
      return;
    }

    // Set publishable key and load Stripe
    setPublishableKey(pub_key_response.data.publishable_key);
    setStripePromise(() => loadStripe(pub_key_response.data.publishable_key));
    // stripePromise = loadStripe(pub_key_response.data.publishable_key);
    // Create payload
    //console.log("ORDER", data.order);
    const order = data.order;
    const mainProduct = order.products.find((product) => product.type === "main");
    const payload = {
      buyer: order.buyer,
      products: order.products,
      purchase_id: order.purchase_id,
      main_product: mainProduct,
      coupon: order.coupon,
      pathname: window.location.pathname,
      origin: window.location.origin,
      payment_unique_id: data.payment_unique_id,
      is_recovery: true,
    };

    const createCheckoutSessionResponse = await createPaymentSession(payload);
    // Check if there is an error
    if (!createCheckoutSessionResponse.status) {
      setLoading(false);
      setErrorFetching(true);
      return;
    }

    setStripeSessionId(createCheckoutSessionResponse.data.session_id);

    setStripeData({
      session_id: createCheckoutSessionResponse.data.session_id,
      customer: createCheckoutSessionResponse.data.customer,
      success_url: createCheckoutSessionResponse.data.success_url,
      setup_intent: createCheckoutSessionResponse.data.setup_intent,
      client_secret: createCheckoutSessionResponse.data.client_secret,
      payment_unique_id: createCheckoutSessionResponse.data.payment_unique_id,
    });

    setLoading(false);
  };

  const createPaymentSession = (payload) => {
    return new Promise((resolve) => {
      try {
        stripeV4CreatePaymentSession(payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const getPublishableKey = (product_id) => {
    return new Promise((resolve) => {
      try {
        payment_stripe_publishable_key_get(product_id)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  return (
    <>
      <Spin spinning={loading}>
        {errorFetching && !loading && (
          <div className="error">
            <Alert
              message="Oops, ocorreu um erro ao inicializar o cartão de crédito."
              description="Não foi possível apresentar o pedido para cartão de crédito. Tenta novamente dentro de alguns segundos."
              type="error"
              showIcon
            />
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Button type="primary" onClick={() => prepareCheckout()}>
                Tentar novamente
              </Button>
            </div>
          </div>
        )}
        {stripeData.client_secret !== "" && !loading && (
          <div className="credit-card-container">
            <Elements
              options={{
                appearance: { theme: "stripe" },
                clientSecret: stripeData.client_secret,
                locale: "pt",
              }}
              stripe={stripePromise}
            >
              <CheckoutForm
                data={{ ...data, purchase_id: data?.order.purchase_id, main_product: data?.order.main_product }}
                actionHandler={() => {}}
                stripeData={stripeData}
                paymentCompleted={() =>
                  initCheckPayment({
                    ...data?.payment_method_details,
                    method_id: "CREDITCARD",
                    payment_unique_id: data?.payment_unique_id,
                  })
                }
              />
            </Elements>
          </div>
        )}

        {waitForPayment && !errorFetching && !loading && (
          <div className="payment-loading">
            <Alert
              message={
                <div className="payment-loading-alert">
                  <LoadingOutlined /> A aguardar pagamento...
                </div>
              }
              type="warning"
            />
          </div>
        )}
      </Spin>
    </>
  );
};

export default ChargeCreditCard;
