import React from "react";
import { Typography, Tooltip, Button, Divider, Card, Alert } from "antd";
import Support from "../../../Components/Common/ProductContentSupport";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const CourseInternal = ({ item, index, length }) => {
  return (
    <>
      <Card
        size="small"
        type="inner"
        title={
          <>
            <strong>Produto: </strong>
            <span style={{ fontWeight: 400 }}>{item.product_name}</span>
          </>
        }
        style={{ marginBottom: 7 }}
      >
        {item?.refunded === true ? (
          <>
            <Alert
              message="Produto indisponível"
              description="Este produto foi reembolsado ou está em processo de reembolso."
              type="error"
              showIcon
            />
          </>
        ) : (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <Text type="secondary" mark>
                <em>Curso na SalesPark</em>
              </Text>
            </div>
            <div>
              <Tooltip title="Ir para o curso" placement="topRight">
                <Link
                  to={`${process.env.REACT_APP_ACADEMY_URL}/courses/${item.internal_course_id}`}
                  target="_blank"
                >
                  <Button type="primary" className="mr-2">
                    Ir para o curso <RightOutlined />
                  </Button>
                </Link>
              </Tooltip>
            </div>
          </div>
        )}

        <Support data={item.support} />
      </Card>
      {index !== length - 1 && <Divider dashed style={{ borderColor: "#838383" }} />}
    </>
  );
};

export default CourseInternal;
