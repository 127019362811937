import React from "react";

const ExternalManagement = ({ productData, grandTotal }) => {
  return (
    <>
      <div className="external-management-info">Os preços já incluem impostos (se aplicável)</div>
      {/* {productData.management === "external" && (
        <div className="external-management-info">Este é um produto físico.</div>
      )} */}
    </>
  );
};
export default ExternalManagement;
