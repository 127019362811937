import moment from "moment-timezone";
import { ClockCircleOutlined } from "@ant-design/icons";

const EventCountdown = ({ startDate }) => {
  const timeLeft = moment(startDate).diff(moment(), "minutes");

  if (timeLeft < 0) return <></>;

  return (
    <div>
      <ClockCircleOutlined /> <strong>Faltam {moment(startDate).fromNow(true)} para o evento.</strong>
    </div>
  );
};

export default EventCountdown;
