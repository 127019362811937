import React, { useState, useEffect } from "react";
import { Button, Drawer, Form, Input, Spin, notification, Modal } from "antd";
import { attendee_put } from "../../../../../../api/attendees";

const { confirm } = Modal;

const AddAttendee = ({ visible, close, data, purchase_id, token }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [visible]);

  const updateAttendeeConfirm = async (purchase_id, token, attendee, payload) => {
    confirm({
      title: `Definir Participante nº ${data?.buyer_attendee_number}`,
      content: (
        <span>
          Tem a certeza que queres definir este participante como o endereço de e-mail{" "}
          <strong>{payload?.email}</strong>?
        </span>
      ),
      onOk() {
        updateAttendeeReConfirm(purchase_id, token, attendee, payload);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const updateAttendeeReConfirm = async (purchase_id, token, attendee, payload) => {
    confirm({
      title: "ATENÇÃO",
      content: (
        <span>
          Tem mesmo a certeza que os dados do participante estão corretos?{" "}
          <strong>Não será possível alterar depois.</strong>
        </span>
      ),
      onOk() {
        updateAttendeeAction(purchase_id, token, attendee, payload);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const updateAttendeeAction = async (purchase_id, token, attendee, payload) => {
    setIsLoading(true);
    const res = await dbPutAttendee(purchase_id, token, attendee, payload);
    if (!res.status) {
      notification.error({
        message: "Oops!",
        description: "Não foi possível adicionar o participante. Tenta novamente mais tarde.",
      });
      setIsLoading(false);
      return;
    }

    notification.success({
      message: "Participante adicionado com sucesso",
      description: "O participante foi adicionado com sucesso ao evento.",
    });

    setIsLoading(false);
    close(true);
  };

  const dbPutAttendee = (purchase_id, token, attendee, payload) => {
    return new Promise((resolve) => {
      try {
        attendee_put(purchase_id, token, attendee, payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const onFinish = (values) => {
    console.log("onFinish", values);
    updateAttendeeConfirm(purchase_id, token, data._id, values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification.error({
      message: "Erro ao adicionar participante",
      description: "Verifica se todos os campos obrigatórios foram preenchidos.",
    });
  };

  return (
    <Drawer
      title={
        <span>
          Definir Participante com o Bilhete: {data?.buyer_attendee_number} <small>({data?.ticket_code})</small>
        </span>
      }
      width={600}
      visible={visible}
      closable={true}
      onClose={close}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button disabled={isLoading} onClick={close} style={{ marginRight: 8 }}>
            Cancelar
          </Button>
          <Button disabled={isLoading} type="primary" htmlType="submit" form="frm-add-attendee">
            Definir Participante
          </Button>
        </div>
      }
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          form={form}
          id="frm-add-attendee"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "O nome do participante é obrigatório",
              },
            ]}
          >
            <Input placeholder="Nome do Participante" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "O endereço de e-mail do participante é obrigatório",
              },
            ]}
          >
            <Input type="email" placeholder="Endereço de e-mail do Participante" />
          </Form.Item>
          <Form.Item label="Telefone" name="phone">
            <Input type="tel" placeholder="Telefone do Participante" />
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <Button disabled={isLoading} type="primary" htmlType="submit">
                Definir Participante
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default AddAttendee;
