import React, { useState, useEffect } from "react";
import { Spin, Card, Table, notification, Empty, Tooltip, Button } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { sales_invoicing_get, sales_invoicing_pdf_document_get } from "../../api/sales";
import { formatCurrency } from "../../utils/helper";
import moment from "moment-timezone";
import useMediaQuery from "use-media-antd-query";

const table_locale = {
  emptyText: (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<Spin spinning={true} tip="A preparar documentos...." />}
    ></Empty>
  ),
};

const Documents = ({ sale, token, management }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);

  const colSize = useMediaQuery();
  //console.log("colSize", colSize);
  const mobileSizes = ["xs", "sm", "md"];
  const isMobileSize = mobileSizes.includes(colSize);
  useEffect(() => {
    if (sale?.billing_data_completed === true && management === "internal") {
      loadData(sale?.purchase_id, token);
    }
  }, []);

  const loadData = async (purchase_id, token) => {
    setIsLoading(true);
    const response = await dbGetDocuments(purchase_id, token);
    if (response.status) {
      setDocuments(response.data);
      checkDraft(response.data, purchase_id, token);
    } else {
      // notification.error({
      //   message: "Oops!",
      //   description: "Não foi possível carregar os documentos. Tenta novamente mais tarde.",
      // });
      setTimeout(() => {
        loadData(purchase_id, token);
      }, 5000);
    }

    setIsLoading(false);
  };

  const checkDraft = (documents, purchase_id, token) => {
    try {
      let isDraft = false;
      if (documents?.length > 0) {
        for (const doc of documents) {
          if (doc?.invoice_readable_number === "rascunho") {
            isDraft = true;
            break;
          }
        }
      }
      if (isDraft) {
        setTimeout(() => {
          loadData(purchase_id, token);
        }, 5000);
      }
    } catch (error) {
      //
    }
  };

  const getPDFDocument = async (purchase_id, document_id, token) => {
    setIsLoading(true);
    const response = await dbGetPDFDocument(purchase_id, document_id, token);
    if (response.status) {
      // console.log(response.data);
      window.open(response.data, "_blank");
    } else {
      notification.error({
        message: "Oops!",
        description: "Não foi possível descarregar o documento. Tenta novamente mais tarde.",
      });
    }
    setIsLoading(false);
  };

  const dbGetDocuments = (purchase_id, token) => {
    return new Promise((resolve) => {
      try {
        sales_invoicing_get(purchase_id, token)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const dbGetPDFDocument = (purchase_id, document_id, token) => {
    return new Promise((resolve) => {
      try {
        sales_invoicing_pdf_document_get(purchase_id, document_id, token)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const tableColumns = [
    {
      title: "Nº Documento",
      dataIndex: "invoice_readable_number",
      width: "130px",
    },
    {
      title: "Data",
      dataIndex: "doc_date",
      elipsis: true,
      width: "120px",
      hidden: isMobileSize,
      render: (text, record) => {
        return moment(record.doc_date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Descrição",
      dataIndex: "description",
      elipsis: true,
      hidden: isMobileSize,
      render: (_, record) => {
        return <>{record?.product_name}</>;
      },
    },
    {
      title: "Valor",
      dataIndex: "total",
      elipsis: true,
      align: "right",

      render: (_, record) => {
        return <span>{formatCurrency(record?.total)}</span>;
      },
    },
    {
      title: "",
      dataIndex: "actions",
      align: "center",
      width: "70px",
      render: (_, elm) => (
        <div className="text-right d-flex justify-content-end">
          <Tooltip title="Efetuar download do documento" placement="topRight">
            <Button
              disabled={elm?.invoice_readable_number === "rascunho"}
              type="primary"
              className="mr-2"
              icon={<CloudDownloadOutlined />}
              onClick={() => {
                getPDFDocument(elm.identifier, elm.invoice_number, token);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const filteredTableColumns = tableColumns.filter((col) => !col.hidden);
  return (
    <Spin spinning={isLoading}>
      <Card className="documents-card" size="small">
        <div className="documents">
          <h3 className="section-title">Fatura(s)</h3>
          {management === "external" && (
            <div style={{ textAlign: "center", paddingTop: 10, paddingBottom: 10 }}>
              <h4 className="external-h4">A fatura desta compra é emitida diretamente pelo produtor</h4>
              <h5 className="external-h5">Aguarda o envio da fatura pelo produtor</h5>
            </div>
          )}
          {management === "internal" && (
            <Table
              className="documents-table"
              bordered
              locale={table_locale}
              columns={filteredTableColumns}
              dataSource={documents}
              pagination={false}
            ></Table>
          )}
        </div>
      </Card>
    </Spin>
  );
};

export default Documents;
