import moment from "moment-timezone";
import { CalendarOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import AddToCalendarButton from "../AddToCalendar/Button";

const { Text } = Typography;

const EventDate = ({ item, productName, setShowAddToCalendar, isMobile }) => {
  if (!item.start_date || !item.end_date) return <></>;
  // Check if start and end date are int the same day
  const startDate = moment(item.start_date);
  const endDate = moment(item.end_date);
  const isSameDay = startDate.isSame(endDate, "day");

  if (isSameDay) {
    return (
      <div style={{ display: isMobile ? "grid" : "flex", alignItems: "center" }}>
        <Text>
          <CalendarOutlined /> <strong>Data e Hora:</strong> {moment(item.start_date).format("DD-MM-YYYY")}
          <strong> - </strong>
          {moment(item.start_date).format("HH:mm")}
          <span style={{ marginLeft: 5, marginRight: 5 }}>
            <ArrowRightOutlined />
          </span>
          {moment(item.end_date).format("HH:mm")}
        </Text>
        <AddToCalendarButton item={item} productName={productName} setShowAddToCalendar={setShowAddToCalendar} />
      </div>
    );
  }

  return (
    <div style={{ display: isMobile ? "grid" : "flex", alignItems: "center" }}>
      <Text>
        <CalendarOutlined /> <strong>Data e Hora:</strong> {moment(item.start_date).format("DD-MM-YYYY HH:mm")}
        <span style={{ marginLeft: 5, marginRight: 5 }}>
          <ArrowRightOutlined />
        </span>
        {moment(item.end_date).format("DD-MM-YYYY HH:mm")}
      </Text>
      <AddToCalendarButton item={item} productName={productName} setShowAddToCalendar={setShowAddToCalendar} />
    </div>
  );
};

export default EventDate;
