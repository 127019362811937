import axios from "axios";

const api_request = (headers) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000,
    // headers: {
    //   "Content-Type": "application/json",
    //   "x-email": auth_data.email,
    //   "x-guserid": auth_data.guserid,
    //   "x-token": auth_data.token,
    // },
  });
};

export const analytics_create_event_post = async (event, payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post(`/analytics/create-event/${event}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};
