import { Select } from "antd";
const { Option, OptGroup } = Select;

const selectRegionOptions = (country) => {
  return country.regions.map((region, j) => (
    <Option key={j} value={`${country.value}-${region.value}`} label={`${country.localName} - ${region.name}`}>
      <span class={`flag-sprite-small flag-sprite-${country.flagSprite}`} style={{ marginRight: 5 }}>
        {country.flag}
      </span>
      {region.name}
    </Option>
  ));
};

const flagAndName = (country) => (
  <>
    <span class={`flag-sprite flag-sprite-${country.flagSprite}`} style={{ marginRight: 5 }}>
      {country.flag}
    </span>
    {country.localName}
  </>
);

const SelectGeoLocationOptions = ({ countries, selectedCountry, selectedRegion, onChange }) => {
  //console.log("SelectGeoLocationOptions", selectedCountry, selectedRegion);
  const selectionKey = `${selectedCountry.value}-${selectedRegion.value}`;
  const optionsList = countries.map((country, i) => {
    if (country.regions.length === 1) {
      const key = `${country.value}-${country.regions[0].value}`;
      return (
        <Option key={key} value={key} label={country.localName}>
          {flagAndName(country)}
        </Option>
      );
    } else {
      return (
        <OptGroup key={`group${i}`} label={flagAndName(country)}>
          {selectRegionOptions(country)}
        </OptGroup>
      );
    }
  });

  return (
    <Select
      style={{ minWidth: 150 }}
      optionLabelProp="label"
      value={selectionKey}
      onChange={(e) => {
        const [country, region] = e.split("-");
        onChange(country, region);
      }}
    >
      {optionsList}
    </Select>
  );
};

export default SelectGeoLocationOptions;
