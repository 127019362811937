import React, { useState, useEffect } from "react";
import { Card, Button } from "antd";
import { CheckCircleTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import { formatCurrency, IsNullUndefinedEmpty } from "../../../utils/helper";

const gridStyle = {
  width: "100%",
  textAlign: "center",
  cursor: "pointer",
};

const PriceOptions = ({ productData, selectedPriceOption, selectPriceOption, priceInfo }) => {
  if (IsNullUndefinedEmpty(productData?.offers)) return <></>;
  return productData?.offers.map((offer) => (
    <Card.Grid
      key={offer.offer_id}
      style={gridStyle}
      className={selectedPriceOption?.offer_id === offer.offer_id ? "card-selected" : "card"}
      onClick={() => selectPriceOption(offer.offer_id)}
    >
      <div className="flex-space-between">
        <div className="card-name">
          {selectedPriceOption?.offer_id === offer.offer_id && <CheckCircleTwoTone twoToneColor={"#52c41a"} />}
          <span style={{ paddingLeft: selectedPriceOption?.offer_id === offer.offer_id ? 10 : 0 }}>
            {offer.name}
          </span>
        </div>
        <div className="card-price">{priceInfo(offer)}</div>
      </div>
    </Card.Grid>
  ));
};

const SelectedOffer = ({ selectedPriceOption }) => {
  if (IsNullUndefinedEmpty(selectedPriceOption)) return <></>;

  return (
    <div className="right">
      <Button type="link">
        <InfoCircleTwoTone /> Alterar
      </Button>
      <h3>
        {selectedPriceOption?.n_installments > 1 ? (
          <>
            {selectedPriceOption?.n_installments} x {formatCurrency(selectedPriceOption?.price)}
          </>
        ) : (
          <>{formatCurrency(selectedPriceOption?.price)}</>
        )}
      </h3>
    </div>
  );
};

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 **********************************************************************************************/
const SelectPriceOption = ({ productData, data, actionHandler }) => {
  const [selectedPriceOption, setSelectedPriceOption] = useState(undefined);
  const [hidePriceOptions, setHidePriceOptions] = useState(false);

  useEffect(() => {
    //console.log("SelectPriceOption", data);
    UpdatePanel(data, true);
  }, [data]);

  const UpdatePanel = (_data, hide = true) => {
    // if (IsNullUndefinedEmpty(_data["price_option"]["offer_id"])) return;
    setSelectedPriceOption(data?.price_option);
    if (hide === true) setHidePriceOptions(true);
  };

  const getOfferData = (offer_id) => productData?.offers.find((offer) => offer.offer_id === offer_id);

  const selectPriceOption = (offer_id) => {
    // setSelectedPriceOption(offer_id);
    setTimeout(() => {
      setHidePriceOptions(true);
    }, 500);
    actionHandler("price_option", getOfferData(offer_id));
  };

  const priceInfo = (offer) => {
    if (offer.n_installments > 1) return `${offer.n_installments} x ${formatCurrency(offer.price)}`;
    return formatCurrency(offer.price);
  };

  if (productData?.offers.length === 1) return <></>;

  return (
    <div className="price-option-container mb-10">
      {!hidePriceOptions && (
        <Card className="card-group card-title" size="small" title="Seleciona a forma de Pagamento">
          <PriceOptions
            productData={productData}
            selectedPriceOption={selectedPriceOption}
            selectPriceOption={selectPriceOption}
            priceInfo={priceInfo}
          />
        </Card>
      )}

      {hidePriceOptions && (
        <Card size="small" className="card-option-selected" hoverable onClick={() => setHidePriceOptions(false)}>
          <div className="flex-space-between selected-item">
            <h3>
              <CheckCircleTwoTone twoToneColor={"#52c41a"} /> Forma de Pagamento
            </h3>
            <SelectedOffer selectedPriceOption={selectedPriceOption} />
          </div>
        </Card>
      )}
    </div>
  );
};

export default SelectPriceOption;
