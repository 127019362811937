import { Card } from "antd";
import AppVersion from "../Shared/AppVersion";
const Version = () => {
  return (
    <Card size="small" className="card-powered no-radius-top">
      <div className="text-center">
        <AppVersion />
      </div>
    </Card>
  );
};

export default Version;
