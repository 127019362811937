import { Tooltip } from "antd";
const AppVersion = () => {
  const year = new Date().getFullYear();
  return (
    <span className="app-version">
      <Tooltip title="Feito com amor e muito café">
        <span>© {year} SalesPark - Feito com ❤️ e ☕️ </span>
        <span className="versions">v2.1.355</span>
      </Tooltip>
    </span>
  );
};

export default AppVersion;
