import React from "react";
import { Button, Result } from "antd";

const PurchaseCompleted = () => {
  return (
    <div style={{ height: "100vh", display: "grid", justifyItems: "center", alignItems: "center" }}>
      <Result
        status="success"
        title="Pagamento Concluído"
        subTitle="Parabéns, a tua compra foi efetuada com sucesso!"
      />
    </div>
  );
};

export default PurchaseCompleted;
