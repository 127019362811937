import React, { useEffect, useState } from "react";
import { Typography, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Multibanco from "./Multibanco";
import Paypal from "./PayPal";
import CreditCard from "./CreditCard";
import MBWay from "./MBWay";
import { store } from "../../../utils/helper";
import { payment_check_get } from "../../../api/payments";
import mb from "../../../assets/images/mb.2.png";
import mbway from "../../../assets/images/mbway.2.png";
import creditcard from "../../../assets/images/creditcard.2.png";
import paypal from "../../../assets/images/paypal.2.png";

import { pixelEventPurchase } from "utils/pixels";

const { Title } = Typography;
const isWidget = window?.location?.hash?.substr(1) === "widget";

const Payment = ({ productData, data, actionHandler, step }) => {
  const [paid, setPaid] = useState(false);
  const [redirectToken, setRedirectToken] = useState(undefined);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [showPurchaseCompleted, setShowPurchaseCompleted] = useState(false);

  const [redirectModalMessage, setRedirectModalMessage] = useState(
    "Estamos só a terminar o teu processo de compra..."
  );
  const paymentMethod = data?.payment_method;
  const paymentMethodId = data?.payment_method.method_id;
  const purchaseProducts = data?.purchase_products;

  const statusAction = data?.status?.action;
  const statusData = data?.status?.data;

  let timerCheck;

  const titles = {
    creditcard: "Pagamento com cartão de crédito",
    mb: "Pagamento com Referência Multibanco",
    paypal: "Pagamento com PayPal",
    mbway: "Pagamento com MB WAY",
  };

  const getMethodImage = (method_id) => {
    switch (method_id) {
      case "mbway":
        return mbway;
      case "mb":
        return mb;
      case "creditcard":
        return creditcard;
      case "paypal":
        return paypal;
      default:
        return "";
    }
  };

  useEffect(() => {
    initCheckPayment();
  }, [statusAction]);

  useEffect(() => {
    if (redirectToken !== undefined) {
      let total = data?.purchase_products?.reduce((acc, item) => acc + item.price, 0);
      total = parseFloat(total.toFixed(2));

      pixelEventPurchase({ ...data, value: total });
      clearTimeout(timerCheck);

      let customPage = false;
      let redirect_url = `/billing/${data?.purchase_id}/?token=${redirectToken}`;
      let redirect_text = "Feito! Estamos a redirecionar para a faturação...";

      if (data?.thankyou_page?.is_external === true && data?.thankyou_page?.url.includes(".")) {
        customPage = true;
        redirect_url = data?.thankyou_page?.url;
        redirect_text = "Feito! Estamos a redirecionar para a página de instruções...";
      }

      if (isWidget && !customPage) {
        redirect_text = null;
      }
      const timeout = isWidget ? 1500 : 5000;

      if (redirect_text !== null) setRedirectModalMessage(redirect_text);

      setTimeout(() => {
        // Is normal pageview
        if (!isWidget) {
          window.location.href = redirect_url;
          return;
        }

        // Is normal pageview
        if (isWidget) {
          if (customPage) {
            window.top.location.href = redirect_url;
            return;
          } else {
            window.location.href = `/${productData?.product_id}/purchase-completed/?token=${redirectToken}`;
            return;
          }
        }
      }, timeout);
    }
  }, [redirectToken]);

  const initCheckPayment = async () => {
    if (statusAction === "wait-for-payment") {
      let checks = store("GET", "checks", "INT");
      checks++;
      store("SET", "checks", checks);
      clearTimeout(timerCheck);
      timerCheck = setTimeout(() => {
        checkPayment();
      }, 1000);
    }
  };

  const checkPayment = async () => {
    let isPaid = false;
    payment_check_get(data.purchase_id, statusData.method_id, statusData.entity, statusData.reference)
      .then((response) => {
        if (response.data.paid === true) {
          if (!paid) {
            actionHandler("update_status", {
              action: "paid",
              data: {
                purchase_id: response.data.purchase_id,
                entity: response.data.entity,
                reference: response.data.reference,
                phone: response.data.phone,
              },
            });
          }
          setPaid(true);
          isPaid = true;

          if (response.data.token !== undefined) {
            setRedirectToken(response.data.token);
            setShowRedirectModal(true);
          }
        }
      })
      .catch((error) => {
        //console.log("error", error);
      })
      .finally(() => {
        if (isPaid === false) {
          setTimeout(() => {
            initCheckPayment();
          }, 5000);
        }
      });
  };

  // console.log("Payment", paymentMethodId, purchaseProducts);

  return (
    <div className="payment-container">
      <div
        className="payment-container-top"
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <div className="title">
          <Title level={3}>{titles[paymentMethodId]}</Title>
        </div>
        <div className="payment-icon">
          <img height={35} src={getMethodImage(paymentMethodId)} alt={paymentMethod?.name} />
        </div>
      </div>
      {paymentMethodId === "mb" && (
        <Multibanco productData={productData} data={data} actionHandler={actionHandler} />
      )}
      {paymentMethodId === "mbway" && <MBWay productData={productData} data={data} actionHandler={actionHandler} />}
      {paymentMethodId === "creditcard" && (
        <CreditCard productData={productData} data={data} actionHandler={actionHandler} />
      )}
      {paymentMethodId === "paypal" && (
        <Paypal productData={productData} data={data} actionHandler={actionHandler} />
      )}
      <Modal
        closable={false}
        title="Pagamento Concluído!"
        visible={showRedirectModal}
        okText="Está quase..."
        footer={null}
      >
        <p>
          <LoadingOutlined />
          &nbsp;&nbsp;&nbsp;{redirectModalMessage}
        </p>
      </Modal>
    </div>
  );
};

export default Payment;
