import React, { useState } from "react";
import { Typography, Modal, Button } from "antd";

const { Text, Paragraph } = Typography;

const Producer = ({ data }) => {
  const [showProducerModal, setShowProducerModal] = useState(false);
  console.log(data);

  return (
    <>
      <small>
        <Text className="producer-name">
          <strong>Produtor:</strong>{" "}
          <span onClick={() => setShowProducerModal(true)} className="clickable_hover">
            {data?.name}
          </span>
        </Text>
      </small>

      <Modal
        visible={showProducerModal}
        title="Contacto do Produtor"
        onCancel={() => setShowProducerModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowProducerModal(false)}>
            Fechar
          </Button>,
        ]}
      >
        <strong>Nome: </strong>
        <Paragraph level={5}>{data?.name}</Paragraph>
        <strong>Endereço de e-mail: </strong>
        <Paragraph level={5} copyable={{ tooltips: ["Copiar", "Copiado"] }}>
          {data?.email}
        </Paragraph>
      </Modal>
    </>
  );
};

export default Producer;
