import { Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const CheckingPayment = () => {
  return (
    <div className="payment-loading">
      <Alert
        message={
          <div className="payment-loading-alert">
            <LoadingOutlined /> A verificar pagamento...
          </div>
        }
        type="warning"
      />
    </div>
  );
};

export default CheckingPayment;
