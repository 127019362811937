import { action_unique_id_get } from "../api/actions";
import { product_is_valid_get, product_post } from "../api/products";

export const getProduct = (product_id, offers = "main", country, region, data) => {
  return new Promise((resolve) => {
    try {
      product_post(product_id, offers, country, region, data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));

      // Error Handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

// TODO: Deprecated (no longer used)
export const getPurchaseId = (product_id) => {
  return new Promise(async (resolve) => {
    try {
      let purchase_id = undefined;
      const uniqueId_response = await _getPurchaseId();
      // console.log("uniqueId_response", uniqueId_response);
      if (!uniqueId_response.status) {
        purchase_id = hashFnv32a(product_id, makeid(12), +new Date());
      } else {
        purchase_id = uniqueId_response.data.data; // hashFnv32a(product_id, uniqueId_response.data.data, +new Date());
      }

      //fallback
      if (purchase_id === undefined) {
        purchase_id = hashFnv32a(product_id, makeid(12), +new Date());
      }

      resolve({ status: true, data: purchase_id });

      // Error Handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

export const isProductValid = (product_id) => {
  return new Promise((resolve) => {
    try {
      product_is_valid_get(product_id)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));

      // Error Handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

/**
 *
 *
 *
 * HELPERS
 *
 *
 *
 */

/****************************************************
 * ##: Unique ID generator
 * History:
 * 13-06-2022: Created
 ****************************************************/
const makeid = (length) => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/****************************************************
 * ##: Purchase ID generator
 * TODO: Deprecated (no longer used)
 * History:
 * 13-06-2022: Created
 ****************************************************/
const hashFnv32a = (str, asString, seed) => {
  var i,
    l,
    hval = seed === undefined ? "d10c832a9dc6" : seed;

  for (i = 0, l = str.length; i < l; i++) {
    hval ^= str.charCodeAt(i);
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }
  if (asString) {
    return ("0000000" + (hval >>> 0).toString(16)).substr(-8).toUpperCase();
  }
  return hval >>> 0;
};

const _getPurchaseId = () => {
  return new Promise((resolve) => {
    try {
      action_unique_id_get()
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
