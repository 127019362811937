import moment from "moment-timezone";

export const refundEndDate = (salesData) => {
  if (salesData?.refund_end_date) {
    return moment.utc(salesData?.refund_end_date).local().endOf("day").format("DD-MM-YYYY");
  } else if (salesData?.paid_date) {
    return moment.utc(salesData?.paid_date).add(15, "days").endOf("day").local().format("DD-MM-YYYY");
  } else {
    return <em>Indefinido</em>;
  }
};

export const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
  var hDisplay = hours > 0 ? (hours < 10 ? "0" + hours : hours) : "00";
  var mDisplay = minutes > 0 ? (minutes < 10 ? "0" + minutes : minutes) : "00";
  var sDisplay = seconds > 0 ? (seconds < 10 ? "0" + seconds : seconds) : "00";
  if (completed) {
    // Render a completed state
    window.location.href = "/authentication";
    return;
  } else {
    // Render a countdown
    return (
      <span>
        {hDisplay}:{mDisplay}:{sDisplay}
      </span>
    );
  }
};
