import React, { useState, useEffect } from "react";
import { Card, CardGrid, Row, Col } from "antd";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import {
  paymentMethodsArray,
  getMethodImage,
  mbwayAboveLimit,
  haveInstallments,
  paymentMethodValid,
} from "../../utils/helper";

const PaymentMethodV2 = ({ data, actionHandler, selected, colSize }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(data?.payment_method?.method_id);
  const isMBWAYAboveLimit = mbwayAboveLimit(data);
  const purchaseHaveInstallments = haveInstallments(data.purchase_products);

  const gridStyle = {
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
  };

  //console.log("PaymentMethod_v2", data);

  // Filter payment methods
  const filteredPaymentMethods = paymentMethodsArray.filter((method) =>
    data.allowed_methods.includes(method.method_id)
  );

  useEffect(() => {
    // Check if selected payment method is still available, if not, reset it
    if (filteredPaymentMethods.find((method) => method.method_id === selectedPaymentMethod) === undefined) {
      setSelectedPaymentMethod(undefined);
    }
  }, [data]);

  const getMethodData = (method_id) => filteredPaymentMethods.find((method) => method.method_id === method_id);

  const selectPaymentMethod = (method_id) => {
    setSelectedPaymentMethod(method_id);
    selected(method_id);
    actionHandler("payment_method", getMethodData(method_id));
  };

  const ItemIcon = () => {
    //mbwayAboveLimit
    if (isMBWAYAboveLimit) {
      return <WarningTwoTone twoToneColor={"#eed202"} />;
    } else {
      return <CheckCircleTwoTone twoToneColor={"#52c41a"} />;
    }
  };

  const validatePaymentMethod = (method_id = undefined) => paymentMethodValid(data, method_id);
  const SmallWarning = ({ method_id }) => {
    if (!validatePaymentMethod(method_id).status) {
      return (
        <>
          {" "}
          <small>({validatePaymentMethod(method_id).data})</small>
        </>
      );
    }

    return <></>;
  };

  const methodClass = (method) => {
    return selectedPaymentMethod === method.method_id
      ? validatePaymentMethod(method.method_id).status
        ? "card-selected"
        : "card-warning"
      : "card";
  };

  const PaymentMethodComponent = () => {
    return filteredPaymentMethods.map((method) => {
      if (method.method_id === "paypal" && purchaseHaveInstallments) return null;
      return (
        <Col xs={12} sm={6} key={method.method_id} style={{ marginBottom: colSize === "xs" ? 15 : 0 }}>
          <Card
            size="small"
            style={gridStyle}
            className={methodClass(method)}
            onClick={() => selectPaymentMethod(method.method_id)}
          >
            <div className="payment-method-card">
              <div className="card-image">
                <img height={36} src={getMethodImage(method.method_id)} alt={method.name} />
              </div>
              <div className="card-name">
                <span>
                  {method.name}
                  <SmallWarning method_id={method.method_id} />
                </span>
              </div>
            </div>
          </Card>
        </Col>

        // <Card.Grid
        //   key={method.method_id}
        //   style={gridStyle}
        //   className={
        //     selectedPaymentMethod === method.method_id
        //       ? validatePaymentMethod(method.method_id).status
        //         ? "card-selected"
        //         : "card-warning"
        //       : "card"
        //   }
        //   onClick={() => selectPaymentMethod(method.method_id)}
        // >
        //   <div className="flex-space-between">
        //     <div className="card-name">
        //       {selectedPaymentMethod === method.method_id && <ItemIcon />}
        //       <span style={{ paddingLeft: selectedPaymentMethod === method.method_id ? 10 : 0 }}>
        //         {method.name}
        //         <SmallWarning method_id={method.method_id} />
        //       </span>
        //     </div>
        //     <div className="card-image">
        //       <img height={30} src={getMethodImage(method.method_id)} alt={method.name} />
        //     </div>
        //   </div>
        // </Card.Grid>
      );
    });
  };

  return (
    <Row gutter={15}>
      <PaymentMethodComponent />
    </Row>
  );
};

export default PaymentMethodV2;
