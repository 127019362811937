import React, { useState, useEffect } from "react";
import { Drawer, Button, Row, Col, Card, Tag, notification, Spin } from "antd";
import moment from "moment-timezone";
import { IsNullUndefinedEmpty } from "utils/helper";
import { CheckCircleOutlined, ExclamationCircleOutlined, UserAddOutlined } from "@ant-design/icons";
import AddAttendee from "./AddAttendee";
import { attendees_get } from ".././../../../../api/attendees";

const EventDetails = ({ visible, close, data, purchase_id, token, setUnsetAttendees }) => {
  const [addAttendeeDrawer, setAddAttendeeDrawer] = useState({ visible: false, data: null });
  const [attendees, setAttendees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const numberOfTickets = data?.qty || 1;
  const rowsStyle = { marginBottom: 5, paddingBottom: 5 }; //borderBottom: "1px dashed #d7d7d7",
  const contentStyle = { fontSize: 15 };

  useEffect(() => {
    if (visible) {
      loadAttendees();
    }
  }, [visible]);

  const loadAttendees = async () => {
    if (!purchase_id || !token) {
      notification.error({
        message: "Oops!",
        description: "Não foi possível carregar os participantes do evento. Tenta novamente mais tarde.",
      });
      return;
    }

    setIsLoading(true);
    const res = await dbGetAttendees(purchase_id, token);
    if (!res.status) {
      notification.error({
        message: "Oops!",
        description: "Não foi possível carregar os participantes do evento. Tenta novamente mais tarde.",
      });
      setIsLoading(false);
      return;
    }

    setAttendees(res.data);

    // Check if all attendees are defined
    const unset_attendees = res.data.filter((attendee) => attendee.attendee_defined !== true).length;
    setUnsetAttendees(unset_attendees);
    setIsLoading(false);
  };

  const dbGetAttendees = (purchase_id, token) => {
    return new Promise((resolve) => {
      try {
        attendees_get(purchase_id, token)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  return (
    <Drawer
      title={data?.product_name}
      width={800}
      visible={visible}
      closable={true}
      onClose={close}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={close} type="primary">
            Fechar
          </Button>
        </div>
      }
    >
      <div style={contentStyle}>
        <Card title="Detalhes do Evento" type="inner">
          <div style={rowsStyle}>
            <Row gutter={20}>
              <Col span={12}>
                <strong>Data e Hora de Início:</strong>
                <br />
                {moment(data?.event?.start_date).format("DD/MM/YYYY HH:mm")}
              </Col>
              <Col span={12}>
                <strong>Data e Hora de Fim:</strong>
                <br />
                {moment(data?.event?.end_date).format("DD/MM/YYYY HH:mm")}
              </Col>
            </Row>
          </div>

          <div style={rowsStyle}>
            <strong>Descrição do Evento:</strong>
            <br />
            {data?.event?.description}
          </div>

          {!IsNullUndefinedEmpty(data?.event?.schedule_description) && (
            <div style={rowsStyle}>
              <strong>Programação do Evento:</strong>
              <br />
              {data?.event?.schedule_description}
            </div>
          )}

          {!IsNullUndefinedEmpty(data?.event?.instructions) && (
            <div style={rowsStyle}>
              <strong>Instruções:</strong>
              <br />
              {data?.event?.instructions}
            </div>
          )}

          {data?.format === "online-event" && !IsNullUndefinedEmpty(data?.event?.event_link) && (
            <div style={rowsStyle}>
              <strong>Link do Evento:</strong>
              <br />
              {data?.event?.event_link}
            </div>
          )}
        </Card>

        <Spin spinning={isLoading}>
          <Card
            type="inner"
            style={{ marginTop: 15 }}
            title={numberOfTickets > 1 ? "Bilhetes dos Participantes" : "Bilhete do Participante"}
          >
            {attendees?.map((attendee, index) => (
              <div
                key={index}
                style={{ ...rowsStyle, display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <div>
                  <strong>Nome:</strong> {attendee.name}
                  <br />
                  <strong>Email:</strong> {attendee.email}
                  <br />
                  <strong>Telefone:</strong> {attendee.phone}
                  <br />
                  <small style={{ color: "#9ea6b1" }}>
                    <strong>Bilhete nº:</strong> {attendee.buyer_attendee_number} -{" "}
                    <strong>{attendee.ticket_code}</strong>
                  </small>
                </div>
                {attendee?.attendee_defined !== true && (
                  <div>
                    <Button
                      type="primary"
                      onClick={() =>
                        setAddAttendeeDrawer({ visible: true, data: { ...attendee, event_id: data?.event?.id } })
                      }
                    >
                      Definir Participante <UserAddOutlined />
                    </Button>
                  </div>
                )}
                <div>
                  {attendee?.attendee_defined === true ? (
                    <Tag color="green">
                      Confirmado <CheckCircleOutlined />
                    </Tag>
                  ) : (
                    <Tag color="gold">
                      Não definido <ExclamationCircleOutlined />
                    </Tag>
                  )}
                </div>
              </div>
            ))}
          </Card>
        </Spin>
      </div>
      <AddAttendee
        visible={addAttendeeDrawer.visible}
        close={(status) => {
          setAddAttendeeDrawer({ visible: false, data: null });
          if (status === true) loadAttendees();
        }}
        data={addAttendeeDrawer.data}
        purchase_id={purchase_id}
        token={token}
      />
    </Drawer>
  );
};

export default EventDetails;
