import { Spin } from "antd";
const { Progress, Tooltip, Tag } = require("antd");
const { SyncOutlined } = require("@ant-design/icons");
export const ProgressInstallment = ({ record }) => {
  if (!record) return <></>;
  if (record?.is_recurrence === true) {
    if (record?.n_installments > 0) {
      return recurrenceLimitedItem(record);
    } else {
      return recurrenceForeverItem(record);
    }
  }

  if (record?.is_recurrence !== true) {
    return installmentItem(record);
  }

  return <>???! 😬</>;
};

const recurrenceLimitedItem = (record) => {
  if (!record?.n_installments_paid) return <></>;
  return (
    <>
      <Tooltip
        placement="left"
        title={`Recorrência com ${record?.n_installments_paid} de ${record?.n_installments} ${
          record.n_installments > 1 ? "pagamentos conclúidos" : "pagamento conclúido"
        }`}
      >
        <Tag color="purple" style={{ width: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <SyncOutlined />
            <span>
              {" "}
              {record?.n_installments_paid}/{record.n_installments}{" "}
              {record?.n_installments > 1 ? "pagamentos" : "pagamento"}
            </span>
          </div>
        </Tag>
      </Tooltip>
    </>
  );
};

const recurrenceForeverItem = (record) => {
  if (!record?.n_installments_paid) return <></>;
  return (
    <>
      <Tooltip
        placement="left"
        title={`Recorrência com ${record?.n_installments_paid} ${
          record?.n_installments_paid > 1 ? "pagamentos conclúidos" : "pagamento conclúido"
        }`}
      >
        <Tag color="purple" style={{ width: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <SyncOutlined />
            <span>
              {" "}
              {record?.n_installments_paid} {record?.n_installments_paid > 1 ? "pagamentos" : "pagamento"}
            </span>
          </div>
        </Tag>
      </Tooltip>
    </>
  );
};

const installmentItem = (record) => {
  if (!record?.n_installments) return null;
  let percent = (record?.n_installments_paid / record?.n_installments) * 100;
  let status = "active";
  if (record.completed) status = "success";
  if (record.canceled) status = "exception";
  if (record.uncollectable) status = "exception";
  if (record.refunded) status = "exception";
  return (
    <>
      <Tooltip
        placement="left"
        title={`${record?.n_installments_paid} de ${record?.n_installments} prestações pagas.`}
      >
        <Progress
          percent={percent}
          status={status}
          format={() => (
            <span>
              {record?.n_installments_paid}/{record?.n_installments}
            </span>
          )}
        />
      </Tooltip>
    </>
  );
};

export const LoadPaymentChargeData = () => (
  <div style={{ display: "grid", alignContent: "center", paddingTop: 30, paddingBottom: 30 }}>
    <Spin tip="A preparar dados..."></Spin>
  </div>
);
