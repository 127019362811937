import React from "react";
import { Result } from "antd";
import image from "assets/images/account_suspended.png";
const AccountSuspended = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 25 }}>
        <img className="img-fluid" src={"/img/logo-white.png"} alt="" />
      </div>
      <Result
        icon={<img style={{ width: "80%", maxWidth: "250px" }} src={image} alt="unhappy" />}
        title="Esta conta está temporáriamente suspensa."
        subTitle="Não é possível aceder a este produto. Tenta novamente mais tarde."
      />
    </>
  );
};

export default AccountSuspended;
