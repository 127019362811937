import React from "react";
import { Result } from "antd";

const InvalidCountry = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 25 }}>
        <img className="img-fluid" src={"/img/logo-white.png"} alt="" />
      </div>
      <Result status="403" title="Oops... A venda deste produto não está disponível no teu país." />
    </>
  );
};

export default InvalidCountry;
