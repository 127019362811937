import { Typography } from "antd";
import { IsNullUndefinedEmpty, NotNullUndefinedEmpty } from "../../utils/helper";

const { Title, Paragraph } = Typography;

const getProductAvatar = (p_info) => {
  let name = p_info.name.replace(/ /g, "_").replace(/\s\s+/g, "-").replace(/#/g, "");
  return `https://api.dicebear.com/7.x/initials/svg?seed=${name}`;
};

const getProductImage = (p_info) => {
  let image_url = p_info?.image;
  if (IsNullUndefinedEmpty(image_url)) {
    image_url = getProductAvatar(p_info);
  }
  return image_url;
};

export const ItemLine = ({ label, text, textAlign = "left" }) => {
  return (
    <Paragraph style={{ textAlign: textAlign, marginBottom: 3 }}>
      <strong>{label}:</strong> {text !== undefined ? text : ""}
    </Paragraph>
  );
};

export const TitleItemLine = ({ label, text, level = 5, textAlign = "left" }) => {
  return (
    <Title level={level} style={{ textAlign: textAlign, marginBottom: 3, marginTop: 3 }}>
      <strong>{label}:</strong> {text !== undefined ? text : ""}
    </Title>
  );
};

export const ProductItem = ({ data }) => {
  if (!data) return <></>;

  // let Image = <></>;
  // const image_url = getProductImage(information);
  // if (NotNullUndefinedEmpty(image_url)) {
  //   Image = <img height={60} src={image_url} alt={information.name} className="product-image" />;
  // }
  return (
    <div className="product-title-container">
      {Image}
      <div style={{ display: "inline" }}>
        <Paragraph>{data?.name}</Paragraph>
      </div>
    </div>
  );
};
