import React, { useState, useEffect } from "react";
import { Card, Form, Input, Spin, Layout, Row, Col, Button, Divider, Modal } from "antd";
import { RightCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import SalesParkLogo from "../../assets/images/logo-white.png";
import { sales_billing_request_post, sales_billing_request_confirmation_post } from "../../api/sales";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import AppVersion from "../../Components/Shared/AppVersion";
import WheelchairButton from "../../Components/Wheelchair";
import useMediaQuery from "use-media-antd-query";

const { Header, Content } = Layout;

const renderTime = ({ remainingTime }) => {
  try {
    if (remainingTime === 0) {
      return <div className="timer">Oops</div>;
    }
    const _remainingTime = remainingTime > 60 ? Math.round(remainingTime / 60, 2) : remainingTime;
    let _remainingTimeLabel = remainingTime > 60 ? "minutos" : "segundos";
    if (_remainingTime === 1) {
      _remainingTimeLabel = remainingTime > 60 ? "minuto" : "segundo";
    }
    return (
      <div className="timer">
        <div className="text">Restam</div>
        <div className="value">{_remainingTime}</div>
        <div className="text">{_remainingTimeLabel}</div>
      </div>
    );
  } catch (error) {
    // console.log("error", error);
    return <></>;
  }
};

const Authentication = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAuth, setIsLoadingAuth] = useState(false);
  const [dataCollected, setDataCollected] = useState(false);
  const [startCountDown, setStartCountDown] = useState(false);
  const [step, setStep] = useState(1);
  const colSize = useMediaQuery();

  const mobileSizes = ["xs", "sm", "md"];
  const isMobile = mobileSizes.includes(colSize);

  const PURCHASE_ID = new URLSearchParams(window.location.search).get("purchase_id");

  useEffect(() => {
    if (PURCHASE_ID) {
      form.setFieldsValue({ purchase_id: PURCHASE_ID });
    }
  }, []);

  const billingRequest = (purchase_id, email) => {
    return new Promise((resolve) => {
      sales_billing_request_post(purchase_id, email)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
    });
  };

  const billingRequestConfirmation = (payload) => {
    return new Promise((resolve) => {
      sales_billing_request_confirmation_post(payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
    });
  };

  const onFinishConfirmOTP = async (values) => {
    setIsLoadingAuth(true);

    const response = await billingRequestConfirmation({ ...dataCollected, ...values });
    if (!response.status) {
      Modal.error({
        title: "Oops!",
        content: "Não foi possível validar o código de autenticação. Verifica os dados, e tenta novamente.",
      });
      setIsLoadingAuth(false);
      setStep(1);
      return;
    }

    window.location.href = `/billing/${dataCollected.purchase_id}/?token=${response.data.public_token}`;
    //console.log("response", response.data);
  };

  const onFinishCollectData = async (values) => {
    setIsLoading(true);

    const response = await billingRequest(values.purchase_id, values.email);
    if (!response.status) {
      Modal.error({
        title: "Oops!",
        content: "Não foi possível validar os dados indicados. Verifica os dados, e tenta novamente.",
      });
      setIsLoading(false);
      return;
    }

    // All Good!

    setDataCollected(response.data);
    setStep(2);
    setIsLoading(false);
    setStartCountDown(true);
  };

  return (
    <div className="flex-container">
      <Layout className="flex-box">
        <Header style={{ display: "flex", justifyContent: "center", height: 120 }}>
          <img height={70} src={SalesParkLogo} alt="SalesPark" />
        </Header>
        <Layout style={{ backgroundColor: "white" }}>
          <Content className="authentication-container">
            <Row className="authentication-container-row">
              <Col xs={24} sm={20} md={14} lg={12} xl={12}>
                {step === 1 && (
                  <Card className="authentication-card">
                    <Spin spinning={isLoading} size="large">
                      <Form form={form} layout="vertical" onFinish={onFinishCollectData}>
                        <Form.Item
                          name="purchase_id"
                          label="Código da Compra"
                          rules={[
                            {
                              required: true,

                              message: "O código da compra é de preenchimento obrigatório...",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="email"
                          label="Endereço de e-mail"
                          rules={[
                            {
                              required: true,

                              message: "O endereço de e-mail é de preenchimento obrigatório...",
                            },
                            {
                              type: "email",
                              message: "O endereço de e-mail é inválido...",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item style={{ marginTop: 25 }}>
                          <Button
                            className="tallbtn"
                            loading={isLoading}
                            htmlType="submit"
                            block
                            size="large"
                            type="primary"
                          >
                            Confirmar <RightCircleOutlined />
                          </Button>
                        </Form.Item>
                      </Form>
                    </Spin>
                  </Card>
                )}

                {step === 2 && (
                  <Card className="authentication-card">
                    <Spin spinning={isLoadingAuth} size="large">
                      <Form layout="vertical" onFinish={onFinishConfirmOTP}>
                        <div>
                          <h3>
                            Foi enviado um código para o teu endereço de email
                            {dataCollected.smsSended === true ? " e telemóvel" : ""}.
                          </h3>
                          <p>
                            É necessário copiar o código enviado para o e-mail com o assunto{" "}
                            <strong>O teu código de autenticação: {dataCollected?.purchase_id}</strong>, e colocar
                            na caixa abaixo.
                            <br />
                            Será também enviada uma mensagem de texto (se o número de telemóvel foi indicado durante
                            a compra).
                          </p>
                          <small>
                            <em>
                              O código foi enviado para o endereço de e-mail: {dataCollected?.email}
                              {dataCollected.smsSended === true
                                ? ` e por SMS para o telemóvel com o número: ${dataCollected.phoneNumber}`
                                : ""}
                            </em>
                          </small>
                        </div>
                        <div
                          style={{ textAlign: "center", display: "flex", justifyContent: "center", paddingTop: 15 }}
                        >
                          <CountdownCircleTimer
                            strokeWidth={4}
                            isPlaying={startCountDown}
                            size={85}
                            duration={1800}
                            colors={["#59cf15", "#cfc615", "#cfa115", "#cf2e15"]}
                            colorsTime={[300, 60, 30, 0]}
                            onComplete={() => {
                              setStep(1);
                            }}
                          >
                            {renderTime}
                          </CountdownCircleTimer>
                        </div>
                        <Divider dashed />
                        <Form.Item
                          name="code"
                          label="Código de autenticação"
                          rules={[
                            {
                              required: true,

                              message: "O código da autenticação é de preenchimento obrigatório...",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item style={{ marginTop: 25 }}>
                          <Button
                            className="tallbtn"
                            loading={isLoadingAuth}
                            htmlType="submit"
                            block
                            size="large"
                            type="primary"
                          >
                            Confirmar código <CheckCircleOutlined />
                          </Button>
                        </Form.Item>
                      </Form>
                    </Spin>
                  </Card>
                )}
              </Col>
            </Row>
            {/* <WheelchairButton
              isSmall={true}
              isMobile={isMobile}
              videoUrl="https://iframe.mediadelivery.net/embed/133254/1e7f840b-8394-475d-9f35-687617521fb6"
            /> */}
          </Content>
        </Layout>

        <div style={{ textAlign: "center", padding: 8, paddingTop: 20 }}>
          <AppVersion />
        </div>
      </Layout>
    </div>
  );
};

export default Authentication;
