import React, { useState, useEffect } from "react";
import {
  Card,
  notification,
  Input,
  Spin,
  Layout,
  Row,
  Col,
  Button,
  Result,
  Modal,
  Typography,
  Divider,
  Table,
  Empty,
  Tag,
} from "antd";
import { RightCircleOutlined, LoadingOutlined, LeftCircleOutlined } from "@ant-design/icons";
import SalesParkLogo from "../../assets/images/logo-white.png";
import {
  sales_installments_payment_request_post,
  sales_installments_payment_request_create_charge_post,
} from "../../api/sales";
import { payment_check_get_v2 } from "../../api/payments";
import AppVersion from "../../Components/Shared/AppVersion";
import useMediaQuery from "use-media-antd-query";
import moment from "moment-timezone";
import PaymentModal from "./PaymentModal";
import PurchaseDetails from "./PurchaseDetails";
import ChargeMBWAY from "./ChargeMBWAY";
import ChargeMB from "./ChargeMB";
import ChargeCreditCard from "./ChargeCreditCard";
import { useParams, useSearchParams } from "react-router-dom";
import "../../assets/css/base.css";
import { formatCurrency } from "utils/helper";

const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;

// Get widow dimensions
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

// SET Mobile width definition
const { width } = getWindowDimensions();
const isMobile = width < 1380;

const tagClass = isMobile ? "smallTag" : "";

const parsePaymentMethodNames = (method) => {
  switch (method?.toUpperCase()) {
    case "MBWAY":
      return "MB WAY";
    case "MB":
      return "Multibanco";
    case "CREDITCARD":
      return "Cartão de Crédito";
    case "PAYPAL":
      return "PayPal";
    default:
      return method;
  }
};

const LoadPaymentData = () => (
  <div style={{ padding: 50 }}>
    <Title level={3} style={{ textAlign: "center" }}>
      A carregar dados de pagamento...
    </Title>
  </div>
);
const LoadPaymentChargeData = () => (
  <div style={{ padding: 10 }}>
    <h4>
      <LoadingOutlined /> A preparar dados...
    </h4>
  </div>
);

const ErrorLoadingPaymentData = ({ onClick }) => (
  <Result
    status="error"
    title="Oops! Erro ao carregar os dados."
    subTitle="Não foi possível carregar os dados do pagamento. Tenta novamente mais tarde."
    extra={[
      <Button type="primary" key="retry" onClick={onClick}>
        Tentar novamente
      </Button>,
    ]}
  />
);

const Payments = () => {
  const colSize = useMediaQuery();

  const mobileSizes = ["xs", "sm", "md"];
  const isMobileSize = mobileSizes.includes(colSize);

  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [activeOrder, setActiveOrder] = useState(null);
  const [state, setState] = useState({});
  const [nextInstallmentToPay, setNextInstallmentToPay] = useState(null);
  //const [isReadyToPay, setIsReadyToPay] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState({ visible: false, data: null });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  //  const [chargeResponse, setChargeResponse] = useState(null);
  const [paid, setPaid] = useState(false);

  let timerCheck;
  let { purchase_id } = useParams();

  useEffect(() => {
    if (purchase_id) {
      getInstallmentsPaymentData(purchase_id);
      setSelectedPaymentMethod(null);
    }
  }, []);

  const refreshData = (reset = false) => {
    if (reset) {
      setPaid(false);
      setSelectedPaymentMethod(null);
      setShowPaymentModal({ visible: false, data: null });
    }
    getInstallmentsPaymentData(purchase_id);
  };

  const initCheckPayment = (values) => {
    timerCheck = setTimeout(() => {
      checkPayment(values);
    }, 5000);
  };

  const paymentCompleted = () => {
    Modal.success({
      title: "Pagamento concluído!",
      content: "O pagamento foi concluído com sucesso.",
      onOk: () => {
        setIsLoading(true);
        setTimeout(() => {
          refreshData(true);
        }, 5000);
      },
    });
    refreshData(true);
  };

  const checkPayment = (values) => {
    let isPaid = false;

    if (values === null || values === undefined) {
      initCheckPayment(values);
      return;
    }

    clearTimeout(timerCheck);

    payment_check_get_v2(values.method_id, values.payment_unique_id)
      .then((response) => {
        if (response.data.paid === true) {
          if (!paid) {
            paymentCompleted();
          }
          setPaid(true);
          isPaid = true;
        }
      })
      .catch((error) => {
        if (isPaid === false) {
          initCheckPayment(values);
        }
      })
      .finally(() => {
        if (isPaid === false) {
          initCheckPayment(values);
        }
      });
  };

  const getInstallmentsPaymentData = async (purchase_id) => {
    try {
      setErrorLoading(false);
      setIsLoading(true);

      const response = await dbGetInstallmentsPaymentData(purchase_id);
      if (!response.status) {
        notification.error({
          message: "Erro",
          description: "Não foi possível carregar os dados do pagamento.",
        });
        setIsLoading(false);
        setErrorLoading(true);

        return;
      }

      setState(response.data);
      setActiveOrder(response.data?.active_installment_order);

      setNextInstallmentToPay(response?.data?.installment?.next_installment_number);
      //setIsReadyToPay(response?.data?.installment?.next_action > 0);

      setIsLoading(false);

      // Error handling
    } catch (error) {
      setIsLoading(false);
      setErrorLoading(true);
    }
  };

  const dbGetInstallmentsPaymentData = (purchase_id) => {
    return new Promise((resolve) => {
      try {
        sales_installments_payment_request_post(purchase_id)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const table_locale = {
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>Não existem dados a apresentar...</span>}
      ></Empty>
    ),
  };

  const tableColumns = [
    {
      title: "Nº Prestação",
      dataIndex: "installment_number",
      align: "center",
      width: "100px",
    },
    {
      title: "Data",
      dataIndex: "paid_date",
      elipsis: true,
      align: "center",
      width: "120px",
      hideOnSmall: true,
      render: (_, record) => {
        if (!record.paid_date && record.due_date === undefined) return "";
        if (record.paid) {
          return moment(record.paid_date).format("DD-MM-YYYY");
        } else {
          return <em>{moment(record.due_date).format("DD-MM-YYYY")}</em>;
        }
      },
    },
    {
      title: "Estado",
      dataIndex: "paid",
      align: "center",
      render: (text, record) => {
        if (record.refunded) {
          return (
            <div style={{ textAlign: "center" }}>
              <Tag className={tagClass} color={"red"}>
                Reembolsado
              </Tag>
            </div>
          );
        }

        if (record.canceled) {
          return (
            <div style={{ textAlign: "center" }}>
              <Tag className={tagClass} color={"red"}>
                Cancelado
              </Tag>
            </div>
          );
        }

        if (record.paid) {
          return (
            <div style={{ textAlign: "center" }}>
              <Tag className={tagClass} color={"green"}>
                PAGO
              </Tag>
            </div>
          );
        }

        return (
          <div style={{ textAlign: "center" }}>
            <Tag className={tagClass} color={"volcano"}>
              PENDENTE
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Valor",
      dataIndex: "amount",
      elipsis: true,
      align: "right",

      render: (_, record) => formatCurrency(record.amount, "€", 2),
    },
    {
      title: isMobile ? "Método Pag." : "Método de Pagamento",
      dataIndex: "info-actions",
      align: "center",
      width: "200px",
      render: (text, record) => {
        if (record.refunded) {
          return <></>;
        }

        if (record.canceled) {
          return <></>;
        }

        if (record.paid) {
          return <em>{parsePaymentMethodNames(record.payment_method?.name)}</em>;
        }

        return (
          <>
            {record.installment_number === nextInstallmentToPay && _isValidForPayment === true && (
              <Button
                type="primary"
                size={isMobile ? "small" : "middle"}
                // className="pr-0 pl-0"
                onClick={() => setShowPaymentModal({ visible: true, data: { ...record, order: activeOrder } })}
              >
                Pagar Agora
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const isValidForPayment = (installment) => {
    if (!installment) return false;
    if (installment.next_action === 0) return false;
    if (installment.refunded) return false;
    // if (installment.uncollectable) return false;
    if (installment.canceled) return false;
    if (installment.completed) return false;
    return true;
  };
  const _isValidForPayment = isValidForPayment(state?.installment);
  //const filteredTableColumns = tableColumns.filter((col) => !col.hidden);

  const getResponsiveColumns = (smallScreen) =>
    tableColumns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));

  return (
    <>
      <Layout style={{ backgroundColor: "white" }}>
        <Header style={{ display: "flex", justifyContent: "center", height: 80 }}>
          <img height={70} src={SalesParkLogo} alt="SalesPark" />
        </Header>

        <Content className="installments-payment-container">
          <Row className="installments-payment-container-row">
            <Col xs={24} sm={20} md={20} lg={16} xl={16}>
              <h3>Gestão de Pagamento de Prestações</h3>
              <Card size="small" className="installments-payment-card">
                <Spin spinning={isLoading} size="large">
                  {/********************************************************** 
                          IS LOADING
                        **********************************************************/}
                  {isLoading && <LoadPaymentData />}

                  {/********************************************************** 
                      ERROR LOADING
                     **********************************************************/}
                  {errorLoading && (
                    <ErrorLoadingPaymentData onClick={() => getInstallmentsPaymentData(purchase_id)} />
                  )}
                  {!errorLoading && !isLoading && (
                    <>
                      <PurchaseDetails state={state} />
                      <Divider dashed />
                      <div className="payment-container">
                        {selectedPaymentMethod !== null && (
                          <>
                            <Card>
                              {isPaymentLoading && <LoadPaymentChargeData />}

                              {!isPaymentLoading && selectedPaymentMethod === "CREDITCARD" && (
                                <div className="credit-card">
                                  <div className="payment-data-container">
                                    <ChargeCreditCard
                                      data={showPaymentModal.data}
                                      initCheckPayment={(values) => {
                                        setTimeout(() => {
                                          initCheckPayment(values);
                                        }, 1000);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              {!isPaymentLoading && selectedPaymentMethod === "MB" && (
                                <div className="multibanco-container">
                                  <div className="payment-data-container">
                                    <ChargeMB
                                      data={showPaymentModal.data}
                                      initCheckPayment={(values) => {
                                        setTimeout(() => {
                                          initCheckPayment(values);
                                        }, 1000);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              {!isPaymentLoading && selectedPaymentMethod === "MBWAY" && (
                                <div className="mbway-container">
                                  <div className="payment-data-container">
                                    <ChargeMBWAY
                                      data={showPaymentModal.data}
                                      initCheckPayment={(values) => {
                                        setTimeout(() => {
                                          initCheckPayment(values);
                                        }, 1000);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </Card>
                            <Button type="link" onClick={() => refreshData(true)}>
                              <LeftCircleOutlined /> Cancelar e voltar atrás
                            </Button>
                          </>
                        )}
                      </div>

                      {selectedPaymentMethod === null && (
                        <>
                          <Table
                            size={isMobile ? "small" : "middle"}
                            bordered
                            locale={table_locale}
                            columns={getResponsiveColumns(isMobile)}
                            dataSource={state?.installments_details}
                            pagination={false}
                          />
                        </>
                      )}
                    </>
                  )}
                </Spin>
              </Card>
            </Col>
          </Row>
          {/* <WheelchairButton
              isSmall={true}
              isMobile={isMobile}
              videoUrl="https://iframe.mediadelivery.net/embed/133254/1e7f840b-8394-475d-9f35-687617521fb6"
            /> */}
        </Content>

        <div style={{ textAlign: "center", padding: 8, paddingTop: 20 }}>
          <AppVersion />
        </div>
      </Layout>

      <PaymentModal
        visible={showPaymentModal.visible}
        close={() => setShowPaymentModal({ visible: false, data: null })}
        data={showPaymentModal.data}
        activeOrder={activeOrder}
        installment={state?.installment}
        onSetPaymentMethod={(paymentMethod) => {
          setSelectedPaymentMethod(paymentMethod);
          // createCharge({
          //   ...showPaymentModal.data,
          //   new_payment_method: paymentMethod,
          // });
          setShowPaymentModal({ visible: false, data: showPaymentModal.data });
        }}
      />
    </>
  );
};

export default Payments;
