import React from "react";
import { Divider, Typography, Tag, Row, Col } from "antd";
import { ItemLine, TitleItemLine } from "./helper";
import { ProgressInstallment } from "./helpers";
import moment from "moment-timezone";

const paidDate = (date) => {
  if (!date) return <em>Não foi possível determinar a data...</em>;
  return moment(date).format("DD-MM-YYYY HH:mm");
};

const PaymentStatus = ({ installment }) => {
  if (!installment) return <></>;
  if (installment.refunded) {
    return (
      <Tag className="ml-1" color="red">
        Reembolsado
      </Tag>
    );
  }
  if (installment.uncollectable) {
    return (
      <Tag className="ml-1" color="red">
        Incobrável
      </Tag>
    );
  }
  if (installment.canceled) {
    return (
      <Tag className="ml-1" color="red">
        Cancelado
      </Tag>
    );
  }
  if (installment.completed) {
    return (
      <Tag className="ml-1" color="green">
        Completo
      </Tag>
    );
  }
  return (
    <Tag className="ml-1" color="geekblue">
      Em curso
    </Tag>
  );
};

const PurchaseDetails = ({ state }) => {
  // xs?: ColSpanType | ColSize;
  // sm?: ColSpanType | ColSize;
  // md?: ColSpanType | ColSize;
  // lg?: ColSpanType | ColSize;
  // xl?: ColSpanType | ColSize;
  // xxl?: ColSpanType | ColSize;
  return (
    <>
      <Row gutter={15}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <ItemLine label="Código da Compra" text={state?.purchase_id} />
          <ItemLine label="Produto" text={state?.installment?.product_name} />
          <ItemLine label="Id Produto" text={state?.installment?.product_id} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <ItemLine label="Data Compra" text={paidDate(state?.paid_date)} />
          <ItemLine label="Nome" text={state?.billing_informations?.name} />
          <ItemLine label="E-Mail" text={state?.billing_informations?.email} />
        </Col>
      </Row>
      <Divider dashed />
      <Row gutter={15}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <TitleItemLine level={5} label="Método de Pagamento" text={state?.installment?.payment_method_code} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <div style={{ display: "flex" }}>
            <ProgressInstallment record={state?.installment} />
            <PaymentStatus installment={state?.installment} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PurchaseDetails;
