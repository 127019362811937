import React from "react";
import { Button, Result } from "antd";

const UserBlocked = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 25 }}>
        <img className="img-fluid" src={"/img/logo-white.png"} alt="" />
      </div>
      <Result
        status="500"
        title="Algo correu mal com o processo de compra."
        subTitle="Entra em contacto com suporte da SalesPark."
      />
    </>
  );
};

export default UserBlocked;
