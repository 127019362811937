import React, { useState, useEffect } from "react";
import { Button, notification, Alert, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { sales_installments_payment_request_create_charge_post } from "../../api/sales";
import { purchaseProductsTotal, formatCurrency, formatMBReference } from "utils/helper";
import moment from "moment-timezone";

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 **********************************************************************************************/

const ChargeMB = ({ data, initCheckPayment }) => {
  const [loading, setLoading] = useState(false);
  const [waitForPayment, setWaitForPayment] = useState(false);
  const [paymentDataResponse, setPaymentDataResponse] = useState(data?.payment_method_details);
  const [errorFetching, setErrorFetching] = useState(false);
  const [allowCrateNewReference, setAllowCrateNewReference] = useState(false);

  useEffect(() => {
    console.log("reference_iso_end_date", paymentDataResponse?.reference_iso_end_date);
    if (paymentDataResponse?.reference_iso_end_date === undefined) {
      createCharge();
      return;
    }
    if (moment(paymentDataResponse?.reference_iso_end_date).isBefore(moment())) {
      createCharge();
    } else {
      setPaymentDataResponse({ ...data?.payment_method_details, value: data.amount });
      setWaitForPayment(true);
      setAllowCrateNewReference(true);
      initCheckPayment({
        ...data?.payment_method_details,
        method_id: "MB",
        payment_unique_id: data?.payment_unique_id,
      });
    }
  }, []);

  const createCharge = async () => {
    createChargeAction({ ...data, new_payment_method: "MB" });
  };

  const createChargeAction = async (payload) => {
    try {
      setLoading(true);
      setWaitForPayment(false);
      const response = await dbCreateCharge(payload);

      if (!response.status) {
        notification.error({
          message: "Erro",
          description: "Não foi possível criar o pagamento.",
        });
        setErrorFetching(true);

        setLoading(false);

        return;
      }
      setPaymentDataResponse({ ...response.data });
      initCheckPayment({ ...response.data, method_id: "MB" });
      setErrorFetching(false);
      setWaitForPayment(true);
      setLoading(false);
      setAllowCrateNewReference(false);

      // Error handling
    } catch (error) {
      console.log("error", error);
      setErrorFetching(true);

      setLoading(false);
    }
  };

  const dbCreateCharge = (payload) => {
    return new Promise((resolve) => {
      try {
        sales_installments_payment_request_create_charge_post(payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  return (
    <>
      <Spin spinning={loading} tip="A gerar referência multibanco...">
        {errorFetching && !loading && (
          <div className="error">
            <Alert
              message="Oops, ocorreu um erro ao gerar a referência multibanco."
              description="Não foi possível gerar a referência multibanco para a tua compra. Tente novamente dentro de alguns segundos."
              type="error"
              showIcon
            />
            <div style={{ textAlign: "center" }}>
              <Button type="primary" onClick={() => createCharge()}>
                Tentar novamente
              </Button>
            </div>
          </div>
        )}
        {waitForPayment && !errorFetching && !loading && (
          <>
            <div className="payment-data">
              <div className="payment-text">
                Entidade: <strong>{paymentDataResponse?.entity}</strong>
              </div>
              <div className="payment-text">
                Referência: <strong>{formatMBReference(paymentDataResponse?.reference)}</strong>
              </div>
              <div className="payment-text">
                Montante: <strong>{formatCurrency(paymentDataResponse?.value)}</strong>
              </div>
              <div className="payment-text-small text-muted">
                <div>
                  Data limite de pagamento:{" "}
                  {moment.utc(paymentDataResponse.reference_iso_end_date).local().format("DD-MM-YYYY")}
                </div>
                {allowCrateNewReference && (
                  <div style={{ marginTop: 10 }}>
                    <Button size="small" type="default" onClick={() => createCharge()}>
                      Gerar nova referência
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div className="payment-instructions">
              O pagamento por Referência Multibanco pode ser efetuado em uma ATM ou via Homebanking.
            </div>
          </>
        )}

        {waitForPayment && !errorFetching && (
          <div className="payment-loading">
            <Alert
              message={
                <div className="payment-loading-alert">
                  <LoadingOutlined /> A aguardar pagamento...
                </div>
              }
              type="warning"
            />
          </div>
        )}
      </Spin>
    </>
  );
};

export default ChargeMB;
