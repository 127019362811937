import React, { useState } from "react";
import { Typography, Modal, Button } from "antd";
import WidgetProducer from "../Shared/WidgetProducer";
import { IsNullUndefinedEmpty, NotNullUndefinedEmpty } from "../../utils/helper";
const { Title, Paragraph } = Typography;

const Product = ({ productData, producer, isMobile, isWidget }) => {
  const [showImageModal, setShowImageModal] = useState(false);

  const ProductNameMemo = React.memo(({ information }) => {
    return <ProductName information={information} />;
  });

  const getProductAvatar = (p_info) => {
    let name = p_info.name.replace(/ /g, "_").replace(/\s\s+/g, "-").replace(/#/g, "");
    return `https://api.dicebear.com/7.x/initials/svg?seed=${name}`;
  };

  const getProductImage = (p_info) => {
    let image_url = p_info?.image;
    if (IsNullUndefinedEmpty(image_url)) {
      image_url = getProductAvatar(p_info);
    }
    return image_url;
  };

  const ProductName = ({ information }) => {
    if (!information) return <></>;

    let Image = <></>;
    const image_url = getProductImage(information);
    if (NotNullUndefinedEmpty(image_url)) {
      Image = (
        <img
          onClick={() => setShowImageModal(true)}
          height={60}
          src={image_url}
          alt={information.name}
          className="product-image"
        />
      );
    }

    return (
      <div className="product-title-container">
        {Image}
        <div style={{ display: "inline" }}>
          <Title level={3} className={`product-title ${information?.name.length > 40 ? "product-title-long" : ""}`}>
            {information?.name}
          </Title>
          {isWidget && (
            <WidgetProducer data={producer} />
            // <small>
            //   <strong>Produtor:</strong>{" "}
            //   <span className="clickable_hover" onClick={() => setShowProducerModal(true)}>
            //     {producer.name}
            //   </span>
            // </small>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <ProductNameMemo information={productData?.information} />
        {!isWidget && (
          <Paragraph
            className="product-description"
            ellipsis={{
              rows: 2,
              symbol: "Ver mais",
              expandable: true,
              onEllipsis: (ellipsis) => {},
            }}
            title={productData?.information?.description}
          >
            {productData?.information?.description}
          </Paragraph>
        )}
      </div>
      <Modal
        closable={true}
        title={productData?.information?.name}
        visible={showImageModal}
        footer={
          <Button type="primary" onClick={() => setShowImageModal(false)}>
            Fechar
          </Button>
        }
        onCancel={() => setShowImageModal(false)}
        onOk={() => setShowImageModal(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={getProductImage(productData?.information)} alt={productData.information.name} />
        </div>
      </Modal>
    </>
  );
};

export default Product;
