import React, { useState } from "react";
import { Modal, Button, Typography } from "antd";
import {
  InfoCircleOutlined,
  WhatsAppOutlined,
  PhoneOutlined,
  MailOutlined,
  IdcardOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const ProductContentSupport = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);
  if (!data) return <></>;
  if (!data?.email) return <></>;

  const phone =
    data?.phone && data?.phone?.length > 5 ? (
      <Text copyable={{ tooltips: ["Copiar número de telefone", "Copiado"], text: data.phone }}>
        <strong>
          <a rel="noreferrer" target="_blank" href={`tel:${data.phone}`}>
            +{data.phone.match(/.{1,3}/g).join(" ")}
          </a>
        </strong>
      </Text>
    ) : (
      <em>Não disponível</em>
    );
  const whatsapp =
    data?.whatsapp && data?.whatsapp?.length > 5 ? (
      <Text copyable={{ tooltips: ["Copiar número de WhatsApp", "Copiado"], text: data.whatsapp }}>
        <strong>
          <a rel="noreferrer" target="_blank" href={`https://wa.me/${data.whatsapp?.replace("+", "")}`}>
            +{data.whatsapp.match(/.{1,3}/g).join(" ")}
          </a>
        </strong>
      </Text>
    ) : (
      <em>Não disponível</em>
    );
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 8 }}>
        <Button size="small" type="link" onClick={() => setModalVisible(true)}>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <InfoCircleOutlined /> <span className="ml-1">Contacto do Produtor</span>
          </div>
        </Button>
      </div>
      <Modal
        title="Contacto do Produtor"
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setModalVisible(false)}>
            Fechar
          </Button>,
        ]}
      >
        <p>
          <IdcardOutlined /> Nome: <strong>{data?.name}</strong>
        </p>
        <p>
          <MailOutlined /> Email:{" "}
          <Text copyable={{ tooltips: ["Copiar endereço de e-mail", "Copiado"], text: data?.email }}>
            <strong>
              <a rel="noreferrer" target="_blank" href={`mailto:${data?.email}`}>
                {data?.email}
              </a>
            </strong>
          </Text>
        </p>
        <p>
          <PhoneOutlined /> Telefone: <strong>{phone}</strong>
        </p>
        <p>
          <WhatsAppOutlined /> WhatsApp: <strong>{whatsapp}</strong>
        </p>
      </Modal>
    </>
  );
};

export default ProductContentSupport;
