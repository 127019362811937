import axios from "axios";

const api_request = (headers) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    // headers: {
    //   "Content-Type": "application/json",
    //   "x-email": auth_data.email,
    //   "x-guserid": auth_data.guserid,
    //   "x-token": auth_data.token,
    // },
  });
};

export const sales_get = async (purchase_id, token) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`sales/public/${purchase_id}/${token}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const sales_billing_request_confirmation_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("sales/public/billing-request-confirmation", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const sales_billing_data_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("sales/public/billing-data", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

// export const sales_buyer_data_post = async (payload) => {
//   return new Promise((resolve, reject) => {
//     api_request()
//       .post("sales/public/buyer-data", payload)
//       .then((res) => resolve(res))
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const sales_billing_request_post = async (purchase_id, email) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("sales/public/billing-request", { purchase_id, email })
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const sales_invoicing_get = async (purchase_id, token) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`sales/public/invoicing/${purchase_id}/${token}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const sales_invoicing_pdf_document_get = async (purchase_id, document_id, token) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`sales/public/invoicing/pdf/${purchase_id}/${document_id}/${token}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const sales_product_content_get = async (purchase_id, token) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`sales/public/product-content/${purchase_id}/${token}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const sales_basic_get = async (purchase_id, token) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`sales/public/basic/${purchase_id}/${token}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const sales_refund_request_post = async (purchase_id, token, data) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post(`sales/public/refund/${purchase_id}/${token}`, data)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const sales_cancel_future_payments_post = async (purchase_id, token, data) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post(`sales/public/cancel-future-payments/${purchase_id}/${token}`, data)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const sales_installments_payment_request_post = async (purchase_id) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("sales/public/installments-payment-request", { purchase_id })
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const sales_installments_payment_request_create_charge_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("sales/public/installments-payment-request/create-charge", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};
