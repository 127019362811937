export const POPULAR_DOMAINS = [
  "msn.com",
  "icloud.com",
  "googlemail.com",
  "web.de",
  "cox.net",
  "gmail.com",
  "aim.com",
  "rogers.com",
  "verizon.net",
  "aol.com",
  "aim.com",
  "me.com",
  "zoho.com",
  "yandex.com",
  "sapo.pt",
  "iol.pt",
  "portugalmail.pt",
  "live.com.pt",
  "live.com",
  "hotmail.com",
  "yahoo.com",
  "yahoo.co.uk",
  "outlook.com",
  "outlook.pt",
];

export const POPULAR_TLDS = [
  "com",
  "com.au",
  "com.tw",
  "de",
  "fr",
  "it",
  "ru",
  "org",
  "edu",
  "gov",
  "jp",
  "nl",
  "kr",
  "se",
  "eu",
  "ie",
  "us",
  "at",
  "be",
  "dk",
  "hk",
  "es",
  "gr",
  "ch",
  "no",
  "cz",
  "net",
  "net.au",
  "info",
  "biz",
  "mil",
  "sg",
  "hu",
  "uk",
  "sk",
  "ar",
  "cf",
  "cl",
  "cn",
  "ga",
  "gq",
  "ir",
  "ml",
  "mx",
  "nu",
  "nz",
  "ph",
  "pl",
  "ro",
  "tk",
  "tw",
  "ua",
  "vg",
  "ws",
  "xn",
  "za",
  "app",
  "au",
  "ai",
  "biz",
  "br",
  "blog",
  "cloud",
  "club",
  "cc",
  "de",
  "dev",
  "digital",
  "fi",
  "finance",
  "id",
  "in",
  "io",
  "me",
  "mobi",
  "network",
  "pw",
  "so",
  "xyz",
  "software",
  "to",
  "tech",
  "pt",
  "com.pt",
  "com.br",
  "coach",
];
