import React, { useEffect, useState, useMemo } from "react";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { WarningTwoTone } from "@ant-design/icons";
import { Input, Alert, Spin, Button, Row, Col, notification } from "antd";
import useResponsiveFontSize from "./useResponsiveFontSize";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      showIcon: true,

      style: {
        base: {
          fontSize,
          color: "#424770",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const Errors = ({ cardFieldsStatus }) => {
  let errorsList = [];
  Object.keys(cardFieldsStatus).forEach(function (key) {
    if (!cardFieldsStatus[key].status) {
      errorsList.push(
        <div className="ant-form-item-explain-error error-line">
          <WarningTwoTone twoToneColor={"#e76b72"} /> {cardFieldsStatus[key]?.message}
        </div>
      );
    }
  });
  return <div className="error-list">{errorsList}</div>;
};

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 **********************************************************************************************/
export default function CheckoutForm({ data, stripeData, paymentCompleted, actionHandler, redirect = "always" }) {
  //IBAN: https://github.com/stripe/react-stripe-js/blob/6cc9981ca1da4589bbcf89a57023aa29e9aa45bf/examples/hooks/4-IBAN.js
  //INFO:
  //https://stripe.com/docs/payments/accept-a-payment?ui=elements
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [waitForPayment, setWaitForPayment] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cardFieldsStatus, setCardFieldsStatus] = useState({});
  const [paymentMethodId, setPaymentMethodId] = useState({});
  // const [cardHolder, setCardHolder] = useState(data?.identification?.name);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!stripeData || stripeData.client_secret === "") {
      return;
    }

    setIsLoading(false);
    actionHandler("update_status", {
      action: "checkout",
      data: {},
    });
  }, [stripe]);

  var style = {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: "'Open Sans', sans-serif",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  };

  const _confirmCardPayment = (client_secret) => {
    return new Promise((resolve) => {
      try {
        stripe
          .confirmCardPayment(client_secret, {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: {
                //name: cardHolder,
                email: data?.identification?.email,
                phone: data?.identification?.phone,
              },
            },
          })
          .then((result) => {
            resolve({ status: true, data: result });
          })
          .catch((err) => {
            resolve({ status: false, data: err });
          });
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const confirmSetupUrl = (purchase_id, payment_unique_id, main_product) => {
    let _pui = "";
    let baseLink = window.location.pathname;

    if (payment_unique_id) {
      _pui = `/${payment_unique_id}`;
      baseLink = `/${main_product}`;
    }

    // Create url
    let url = `${baseLink}/confirm-payment/${purchase_id}${_pui}`;
    // Remove double slashs
    url = url.replace(/\/\//, "/");
    url = `${window.location.origin}${url}`;
    console.log("confirmSetupUrl", url);
    return url;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentError(null);
    setIsLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (!data.purchase_id) {
      notification.error({
        message: "Erro",
        description: "Não foi possível criar o pagamento.",
      });
      return;
    }

    // const confirm_reponse = await _confirmCardPayment(stripeData.client_secret);
    // // Promise failed
    // console.log("confirm_reponse", confirm_reponse);
    // if (!confirm_reponse.status) {
    //   setPaymentError("Não foi possível confirmar o pagamento");
    //   setIsLoading(false);
    //   return;
    // }

    // Stripe returns error
    // if (confirm_reponse.data.error) {
    //   setPaymentError(confirm_reponse.data.error.message);
    //   setIsLoading(false);
    //   return;
    // }

    // stripe
    // .confirmCardPayment(client_secret, {
    //   payment_method: {
    //     card: elements.getElement(CardNumberElement),
    //     billing_details: {
    //       name: cardHolder,
    //       email: data?.identification?.email,
    //       phone: data?.identification?.phone,
    //     },
    //   },
    // })

    // const x = await confirmPaymentIntent(stripeData);
    // console.log("x", x);

    // const { error } = await stripe.confirmSetup({
    //   elements,
    //   confirmParams: {
    //     return_url: confirmSetupUrl(data.purchase_id),
    //   },
    // });
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: confirmSetupUrl(data.purchase_id, data?.payment_unique_id, data?.main_product),
      },
      redirect: redirect,
    });

    if (error) {
      // console.log("[error]", error);
      setPaymentError(error.message);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    if (!error) {
      setWaitForPayment(true);
      paymentCompleted();
    }
  };

  const updateFiledsStatus = (field, event) => {
    const msg = event.error ? event.error.message : null;
    const status = event.error ? false : true;
    setCardFieldsStatus({
      ...cardFieldsStatus,
      [field]: { status: status, message: msg },
    });
  };

  const fieldsHasErrors = () => {
    let hasErrors = false;
    Object.keys(cardFieldsStatus).forEach(function (key) {
      if (!cardFieldsStatus[key].status) {
        hasErrors = true;
      }
    });
    return hasErrors;
  };

  return (
    <>
      <Spin spinning={waitForPayment} tip="A aguardar confirmação do pagamento">
        <Spin spinning={isLoading}>
          {paymentError && (
            <div className="payment-error">
              <Alert message="Erro ao processar o pagamento" description={paymentError} type="error" showIcon />
            </div>
          )}

          {elements && stripeData.client_secret && (
            <form onSubmit={handleSubmit} className={`stripe-form ${waitForPayment ? "StripeElementBlur" : ""}`}>
              {/* <Row gutter={[24, 8]}>
                <Col md={14} sm={24}>
                  <label>
                    Número do cartão de crédito
                    <CardNumberElement
                      showIcon={true}
                      options={options}
                      onReady={() => {
                        console.log("CardNumberElement [ready]");
                      }}
                      onChange={(event) => {
                        console.log("CardNumberElement [change]", event);
                        updateFiledsStatus("card", event);
                      }}
                      onBlur={() => {
                        console.log("CardNumberElement [blur]");
                      }}
                      onFocus={() => {
                        console.log("CardNumberElement [focus]");
                      }}
                    />
                  </label>
                </Col>
                <Col md={6} sm={24}>
                  <label>
                    Validade
                    <CardExpiryElement
                      options={options}
                      onReady={() => {
                        console.log("CardNumberElement [ready]");
                      }}
                      onChange={(event) => {
                        console.log("CardNumberElement [change]", event);
                        updateFiledsStatus("expiry", event);
                      }}
                      onBlur={() => {
                        console.log("CardNumberElement [blur]");
                      }}
                      onFocus={() => {
                        console.log("CardNumberElement [focus]");
                      }}
                    />
                  </label>
                </Col>
                <Col md={4} sm={24}>
                  <label>
                    CVC
                    <CardCvcElement
                      options={options}
                      onReady={() => {
                        console.log("CardNumberElement [ready]");
                      }}
                      onChange={(event) => {
                        console.log("CardNumberElement [change]", event);
                        updateFiledsStatus("cvc", event);
                      }}
                      onBlur={() => {
                        console.log("CardNumberElement [blur]");
                      }}
                      onFocus={() => {
                        console.log("CardNumberElement [focus]");
                      }}
                    />
                  </label>
                </Col>
              </Row> */}
              {/* <Row gutter={[24, 8]} className="row-card-holder">
                <Col md={24} sm={24}>
                  <label>
                    Titular do cartão
                    <Input
                      onChange={(e) => setCardHolder(e.target.value)}
                      value={cardHolder}
                      className="card-holder StripeElement-input"
                      placeholder="Nome do titular do cartão"
                    />
                  </label>
                </Col>
              </Row> */}
              <PaymentElement />
              <Row gutter={[24, 8]} className="row-errors">
                <Col md={24} sm={24}>
                  <Errors cardFieldsStatus={cardFieldsStatus} />
                </Col>
              </Row>

              <Button
                block
                className="submit-button"
                disabled={isLoading || !stripe || !elements || fieldsHasErrors() || waitForPayment}
                htmlType="submit"
              >
                <span id="button-text">
                  {isLoading ? <div className="spinner" id="spinner"></div> : "Efetuar Pagamento"}
                </span>
              </Button>
            </form>
          )}
        </Spin>
      </Spin>
    </>
  );
}
