import axios from "axios";

const api_request = (headers) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000,
    // headers: {
    //   "Content-Type": "application/json",
    //   "x-email": auth_data.email,
    //   "x-guserid": auth_data.guserid,
    //   "x-token": auth_data.token,
    // },
  });
};

export const order_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/orders", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const order_get = async (product_id, purchase_id, payment_unique_id) => {
  return new Promise((resolve, reject) => {
    let _pui = payment_unique_id ? `/${payment_unique_id}` : "";
    api_request()
      .get(`/orders/${product_id}/${purchase_id}${_pui}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};
