import { Button, Result } from "antd";

const ErrorGettingProduct = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 25 }}>
        <img className="img-fluid" src={"/img/logo-white.png"} alt="" />
      </div>
      <Result
        status="404"
        title="404"
        subTitle="Oops... Não foi possível carregar os dados do produto. Tenta novamente mais tarde..."
        extra={
          <div>
            <Button
              type="link"
              onClick={() => {
                window.location.href = "https://salespark.io";
              }}
            >
              Ir para o site da SalesPark
            </Button>
            <Button
              type="primary"
              onClick={() => {
                window.location.reload();
              }}
            >
              Tentar novamente
            </Button>
          </div>
        }
      />
    </>
  );
};

export default ErrorGettingProduct;
