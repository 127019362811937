import React, { useState, useEffect } from "react";
import { Drawer, Divider } from "antd";

import { BillingDataFields } from "./BillingFormHelper";

import PurchaseInfo from "./PurchaseInfo";
import PurchaseDetails from "./PurchaseDetails";
import useMediaQuery from "use-media-antd-query";

const DetailsDrawer = ({ visible, close, billing, buyerData, salesData, refundRequest, cancelFuturePaymentys }) => {
  const colSize = useMediaQuery();
  const mobileSizes = ["xs", "sm", "md"];
  const isMobileSize = mobileSizes.includes(colSize);

  return (
    <>
      <Drawer visible={visible} onClose={close} width={isMobileSize ? "100%" : 600}>
        <div>
          <div style={{ textAlign: "center" }}>
            <h2>Detalhes da Compra</h2>
          </div>
          <Divider dashed />

          <div className="purchase-details">
            <h3>Dados de Compra</h3>
            <PurchaseInfo buyerData={buyerData} salesData={salesData} />

            <Divider dashed />
          </div>

          {billing && (
            <div className="billing-details">
              <h3>Dados de Faturação</h3>
              <div className="billing-info-list">
                <BillingDataFields fieldTitle="Nome" fieldValue={billing.name} />
                <BillingDataFields fieldTitle="Contribuinte" fieldValue={billing.vatid} />
                <BillingDataFields fieldTitle="E-mail" fieldValue={billing.email} />
                <BillingDataFields fieldTitle="Telefone" fieldValue={billing.phone} />
                <BillingDataFields fieldTitle="Morada" fieldValue={billing.address} />
                <BillingDataFields fieldTitle="Cidade" fieldValue={billing.city} />
                <BillingDataFields fieldTitle="Código Postal" fieldValue={billing.postal_code} />
                <BillingDataFields fieldTitle="País" fieldValue={billing.country} />
              </div>
            </div>
          )}
          <PurchaseDetails
            salesData={salesData}
            refundRequest={refundRequest}
            cancelFuturePaymentys={cancelFuturePaymentys}
          />
        </div>
      </Drawer>
    </>
  );
};

export default DetailsDrawer;
