import React, { useState, useEffect } from "react";
import { Card, Button, Typography, Divider, Tooltip, Modal } from "antd";
import { CheckCircleTwoTone, DeleteTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";

const OrderBump = ({ orderBump, data, actionHandler, step }) => {
  //console.log("orderBump data", data);
  const ArrowAnimation = () => (
    <div className="arrow-wrapper">
      <div className="arrow-item">
        <div className="arrow1">
          <div className="arrows next"></div>
          <div className="arrows next"></div>
          <div className="arrows next"></div>
        </div>
      </div>
    </div>
  );

  const IsNullUndefinedEmpty = (value) => {
    try {
      if (value === null || value === undefined || value === "") return true;
      return false;
    } catch (error) {
      return true;
    }
  };

  const formatCurrency = (value, currency = "€", decimals = 2) => {
    if (!value || value === undefined) value = 0;
    value = parseFloat(value);
    let r = value.toFixed(decimals);
    //let r = value.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    var parts = r.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    r = parts.join(".");

    if (!!currency) return `${r}${currency}`;
    return r;
  };
  const ModalContent = ({ product }) => {
    return (
      <>
        Tens a certeza que pretendes remover o produto complementar <strong>{product.name}</strong> da tua compra?
      </>
    );
  };
  const confirmRemoveBumpPurchase = (product) => {
    Modal.confirm({
      closable: true,
      destroyOnClose: true,
      maskClosable: true,
      title: "Remover compra?",
      icon: <ExclamationCircleOutlined />,
      content: <ModalContent product={product} />,
      okText: "Sim",
      cancelText: "Não, não quero remover",
      okButtonProps: {
        type: "default",
      },
      cancelButtonProps: {
        type: "danger",
      },
      onOk: () => {
        actionHandler("order-bump-remove-from-cart", product);
      },
    });
  };

  const BumpTitle = ({ data }) => (
    <div style={{ color: data.colors.text }} className="title">
      {data.title}
    </div>
  );

  const BumpDescription = ({ data }) => (
    <div style={{ color: data.colors.text }} className="description">
      {data.description}
    </div>
  );

  const BumpPricing = ({ product, data }) => {
    return (
      <div className="pricing">
        <Divider dashed style={{ margin: 6 }} />
        <span style={{ color: data.colors.text }}>
          Será adicionado à tua compra o produto{" "}
          <strong>
            "<em>{product.name}</em>"
          </strong>{" "}
          no valor de{" "}
          <strong>
            {product.n_installments > 1 ? `${product.n_installments} x ` : ""}
            {formatCurrency(product.price, "€")}
          </strong>
        </span>
      </div>
    );
  };

  const BumpButton = ({ data, product }) => (
    <div className="button">
      <Button
        style={{ background: data.colors.button, borderColor: data.colors.button }}
        size="large"
        type="primary"
        htmlType="submit"
        className="submit-button"
        onClick={() => addOrderBump({ product_id: product.product_id, offer_id: product.offer_id })}
      >
        <ArrowAnimation /> {data.button_title}
      </Button>
    </div>
  );

  const addOrderBump = (product) => {
    // setOrderBumpPurchased({ ...orderBumpPurchased, [index]: true });
    actionHandler("order-bump-add-to-cart", product);
  };

  const BumpPurchased = ({ data, product }) => {
    return (
      <div style={{ paddingTop: 15 }}>
        <Card size="small" className="bump-purchased">
          <div className="bump-purchased-content">
            <div>
              <div className="bump-purchased-content-title">
                <CheckCircleTwoTone twoToneColor={"#52c41a"} /> <span>Complemento adicionado à tua compra</span>
              </div>
              <div className="bump-purchased-content-sub-title">
                <strong>{product.name}</strong> adicionado à tua compra!
              </div>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <Tooltip title="Remover produto das compras">
                <div className="bump-delete-icon">
                  <DeleteTwoTone
                    twoToneColor={"#ff6b72"}
                    className="as-link"
                    onClick={() => confirmRemoveBumpPurchase(product)}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const OrderBumpComponent = () => {
    if (IsNullUndefinedEmpty(orderBump)) return <></>;
    if (IsNullUndefinedEmpty(orderBump?.data) || IsNullUndefinedEmpty(orderBump?.product)) return <></>;
    //console.log("orderBump", data.purchase_products);
    const purchased = data.purchase_products.filter(
      (p) => p.product_id === orderBump.product.product_id && p.offer_id === orderBump.product.offer_id
    ).length;

    return (
      <>
        {purchased === 1 && <BumpPurchased data={orderBump.data} product={orderBump.product} />}
        {purchased === 0 && (
          <div className={`${step === 1 && data.payment_method !== undefined ? "fade-in" : ""}`}>
            <div
              style={{
                "--order-bump": orderBump.data.colors.border,
                backgroundColor: orderBump.data.colors.background,
              }}
              key={orderBump.product.product_id}
              className="active-animation order-bump-container"
            >
              <div>
                <BumpTitle data={orderBump.data} />

                <BumpDescription data={orderBump.data} />
                <BumpPricing product={orderBump.product} data={orderBump.data} />
                <BumpButton data={orderBump.data} product={orderBump.product} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return <OrderBumpComponent />;
};

export default OrderBump;
