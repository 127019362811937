/**
 * HELPER
 */

import { Card, Tooltip, Typography, Divider } from "antd";
import { showProductsListInfo, totalPaymentTooltip, totalPayment } from "./helper";
import { formatCurrency, IsNullUndefinedEmpty } from "utils/helper";

const { Text } = Typography;
const OtherProducts = ({
  mainProduct,
  otherProducts,
  colSize,
  selectedOffer,
  viewDetailedPrices,
  isExternalManagement,
}) => {
  return (
    <Card className="pricing-card">
      <div className="pricing-main">
        <div className="product-price">
          <div className="name">
            <Tooltip placement="left" title={mainProduct.name}>
              {mainProduct.name}
            </Tooltip>
          </div>
          <div className="price">
            {/* <div>{formatCurrency(selectedOffer?.price)}</div> */}{" "}
            {showProductsListInfo(selectedOffer, mainProduct)}
          </div>
        </div>

        {/* Show only if product isnt external management */}
        {viewDetailedPrices && !isExternalManagement && (
          <div className="price-details">
            <div className="price-detail">
              <Text className="price-detail-title">PREÇO:</Text>
              <Text className="price-detail-value">
                {formatCurrency(selectedOffer?.price_details.price_wo_vat)}
              </Text>
            </div>
            <div className="price-detail">
              <Text className="price-detail-title">IVA {selectedOffer?.price_details.vat_tax}%:</Text>
              <Text className="price-detail-value">{formatCurrency(selectedOffer?.price_details.vat_value)}</Text>
            </div>
            {colSize !== "xs" && (
              <div className="price-detail">
                <Text className="price-detail-title">TOTAL A PAGAR:</Text>
                <Text className="price-detail-value">{formatCurrency(selectedOffer?.price)}</Text>
              </div>
            )}
          </div>
        )}
        {selectedOffer?.n_installments > 1 && (
          <div className="installments_disclaimer">
            <Tooltip title={totalPaymentTooltip(selectedOffer)}>
              <Text className="text">
                Vais pagar {totalPayment(selectedOffer)} no final de todas as prestações.
              </Text>
            </Tooltip>
          </div>
        )}
      </div>

      <div className="pricing-multiples">
        {otherProducts?.map((product) => {
          return (
            <div key={product.offer_id}>
              <div className="pricing-multiples">
                <Divider dashed style={{ marginTop: 8, marginBottom: 5 }} />
                <div className="product-price">
                  <div className="name">
                    <Tooltip placement="left" title={product.name}>
                      {product.name}
                    </Tooltip>
                  </div>
                  <div className="price">
                    {/* <div>{formatCurrency(product?.price)}</div> */} {showProductsListInfo(product, mainProduct)}
                  </div>
                </div>
                {viewDetailedPrices && !isExternalManagement && (
                  <div className="price-details">
                    <div className="price-detail">
                      <Text className="price-detail-title">PREÇO:</Text>
                      <Text className="price-detail-value">
                        {formatCurrency(product?.price_details.price_wo_vat)}
                      </Text>
                    </div>
                    <div className="price-detail">
                      <Text className="price-detail-title">IVA {product?.price_details.vat_tax}%:</Text>
                      <Text className="price-detail-value">{formatCurrency(product?.price_details.vat_value)}</Text>
                    </div>
                    {colSize !== "xs" && (
                      <div className="price-detail">
                        <Text className="price-detail-title">TOTAL A PAGAR:</Text>
                        <Text className="price-detail-value">{formatCurrency(product?.price)}</Text>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {product?.n_installments > 1 && (
                <div className="installments_disclaimer">
                  <Tooltip title={totalPaymentTooltip(selectedOffer)}>
                    <Text className="text">
                      Vais pagar {totalPayment(selectedOffer)} no final de todas as prestações.
                    </Text>
                  </Tooltip>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default OtherProducts;
