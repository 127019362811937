import React from "react";
import { Modal, Button } from "antd";
import PaymentMethod from "./PaymentMethod";
const SelectPaymentMethodModal = ({ data, actionHandler, visible, close }) => {
  const handleOk = () => {
    actionHandler("options");
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title="Seleciona o método de Pagamento"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        <Button type="primary" onClick={handleCancel}>
          Fechar
        </Button>
      }
    >
      <div className="options-container">
        <div className="payment-method-container">
          <div className="card-group">
            <PaymentMethod data={data} actionHandler={actionHandler} selected={(method_id) => {}} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectPaymentMethodModal;
