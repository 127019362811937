import React from "react";
import { Typography, Tooltip, Alert, Tag, Card, Divider } from "antd";
import Support from "../../../Components/Common/ProductContentSupport";
 

const { Title, Text } = Typography;

const CourseExternal = ({ item, index, length }) => {
  return (
    <>
      <Card
        size="small"
        type="inner"
        title={
          <>
            <strong>Produto: </strong>
            <span style={{ fontWeight: 400 }}>{item.product_name}</span>
          </>
        }
        style={{ marginBottom: 7 }}
      >
        {item?.refunded === true ? (
          <>
            <Alert
              message="Produto indisponível"
              description="Este produto foi reembolsado ou está em processo de reembolso."
              type="error"
              showIcon
            />
          </>
        ) : (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <Text type="secondary" mark>
                <em>Conteúdo em área de membro externa</em>
              </Text>
            </div>
            <div>
              <Tag color="geekblue">Aguarda instruções do produtor</Tag>
            </div>
          </div>
        )}
         <Support data={item.support} />
      </Card>
      {index !== length - 1 && <Divider dashed style={{ borderColor: "#838383" }} />}
    </>
  );
};

export default CourseExternal;
