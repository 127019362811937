import axios from "axios";

const api_request = (headers) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000,
    // headers: {
    //   "Content-Type": "application/json",
    //   "x-email": auth_data.email,
    //   "x-guserid": auth_data.guserid,
    //   "x-token": auth_data.token,
    // },
  });
};

export const vatid_get = async (vatid) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`/validations/vatid/${vatid}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const validate_iban_post = async (data) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/validations/validate-iban", data)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};
