import React from "react";
import { Result } from "antd";
import image from "assets/images/account_closed.png";
const AccountClosed = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 25 }}>
        <img className="img-fluid" src={"/img/logo-white.png"} alt="" />
      </div>
      <Result
        icon={<img style={{ width: "80%", maxWidth: "250px" }} src={image} alt="unhappy" />}
        title="Esta conta encontra-se encerrada!"
        subTitle="Já não é possível aceder aos produtos desde produtor."
      />
    </>
  );
};

export default AccountClosed;
