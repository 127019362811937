import React from "react";
import { Alert } from "antd";

const OfflinePaymentInfoAlert = () => (
  <div style={{ marginTop: 10 }}>
    <Alert
      className="offline-payment-info"
      message={
        <div className="content">
          Podes fechar esta janela. Os dados de pagamento foram enviados para o teu endereço de e-mail. Após o
          pagmento vais receber as instruções para preencher os dados de faturação e o acesso ao(s) produto(s).
        </div>
      }
      type="info"
    />
  </div>
);

export default OfflinePaymentInfoAlert;
