import axios from "axios";

const api_request = (headers) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000,
    // headers: {
    //   "Content-Type": "application/json",
    //   "x-email": auth_data.email,
    //   "x-guserid": auth_data.guserid,
    //   "x-token": auth_data.token,
    // },
  });
};

export const attendees_get = async (purchase_id, token) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`/attendees/${purchase_id}?token=${token}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const attendee_put = async (purchase_id, token, attendee_id, payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .put(`/attendees/${purchase_id}/${attendee_id}/?token=${token}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};
