import React from "react";
// https://codepen.io/anniedotexe/pen/ExmGvOM

const AttendeeTicket = () => {
  return (
    <div className="attendee-ticket-container">
      <div class="ticket">
        <div class="left">
          <div class="image">
            <p class="admit-one">
              <span>SALESPARK</span>
              <span>SALESPARK</span>
              <span>SALESPARK</span>
            </p>
            <div class="ticket-number">
              <p>3C29Q10M0B8T042M0B8T043</p>
            </div>
          </div>
          <div class="ticket-info">
            {/* <span>BILHETE ONLINE</span> */}

            <div class="show-name">
              <h1>AQUI FICA O NOME DO EVENTO. Mas um nome muito longo!</h1>
              <h2>Olivia Rodrigo</h2>
            </div>
            <div class="time">
              <p>
                25-10-2024 <span> ÀS </span> 09:00
              </p>
              <p>
                25-10-2024 <span> ÀS </span> 18:00
              </p>
              {/* <p>
                DOORS <span>@</span> 7:00 PM
              </p> */}
            </div>
            <p class="location">
              <span>Rua Dr. José Mario Sampaio de Moreira Cruz, Cascais, 3020-125 Lisboa</span>
            </p>
          </div>
        </div>
        <div class="right">
          <p class="admit-one">
            <span>SALESPARK</span>
            <span>SALESPARK</span>
            <span>SALESPARK</span>
          </p>
          <div class="right-info-container">
            <div class="show-name">
              <small>Gil Manuel Encarnação Machado</small>
            </div>
            {/* <div class="time">
              <p>
                8:00 PM <span>TO</span> 11:00 PM
              </p>
              <p>
                DOORS <span>@</span> 7:00 PM
              </p>
            </div> */}
            <div class="barcode">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/QR_deWP.svg/1200px-QR_deWP.svg.png"
                alt="QR code"
              />
            </div>
            {/* <div class="barcode">
              <img src="https://cdn.britannica.com/57/43857-050-9250A718/bar-code.jpg" alt="QR code" />
            </div> */}
            <p class="ticket-number">3C29Q10M0B8T042M0B8T043</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendeeTicket;
