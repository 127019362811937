import { Row, Col, Button, Tooltip, Collapse, Divider } from "antd";
import moment from "moment-timezone";

export const PurchaseInfo = ({ buyerData, salesData }) => {
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <div>
            <div>
              <strong>Nome: </strong>
              <span>{buyerData?.name}</span>
            </div>
            <div>
              <strong>Endereço de e-mail: </strong>
              <span>{buyerData?.email}</span>
            </div>
            <div>
              <strong>Telefone: </strong>
              <span>{buyerData?.phone || <em>Indefinido</em>}</span>
            </div>
            <div>
              <strong>Data Compra: </strong>
              <span>{moment.utc(salesData?.paid_date).local().format("DD-MM-YYYY HH:mm")}</span>
            </div>
            <div>
              <strong>ID da Compra: </strong>
              <span>{salesData?.purchase_id}</span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PurchaseInfo;
