import React from "react";
import { Divider, Collapse, Tooltip, Button } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { formatCurrency, refundAllowed, cancelFuturePaymentsAllowed } from "utils/helper";
import { refundEndDate } from "./IndexHelper";

const { Panel } = Collapse;

const headerName = (salesData) => {
  const productsCount = salesData?.products.length;
  if (productsCount === 1) {
    return `${salesData?.products[0].name}`;
  }
  return (
    <>
      {salesData?.products[0].name}{" "}
      <strong>
        <span style={{ marginLeft: 4 }}> +{productsCount - 1}</span>
      </strong>
    </>
  );
};

const parsePaymentMethod = (paymentMethod) => {
  switch (paymentMethod.toLowerCase()) {
    case "credit_card":
      return "Cartão de Crédito";
    case "creditcard":
      return "Cartão de Crédito";
    case "mb":
      return "Multibanco";
    case "mbway":
      return "MBWAY";
    case "paypal":
      return "PayPal";
    default:
      return paymentMethod;
  }
};
const PurchaseDetails = ({ salesData, refundRequest, cancelFuturePaymentys }) => {
  // console.log("salesData", salesData);

  return (
    <div>
      <Divider dashed />
      <h4 className="section-title">Transação</h4>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className="site-collapse-custom-collapse"
      >
        <Panel header={headerName(salesData)} key="1" className="site-collapse-custom-panel">
          <div>
            <strong>Produto(s): </strong>
            <ul>
              {salesData?.products.map((product, index) => {
                return (
                  <li style={{ marginBottom: 0 }}>
                    <span key={index}>
                      {index + 1}. {product.name}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <strong>Código da Compra: </strong>
            <span>{salesData?.purchase_id}</span>
          </div>
          <div>
            <strong>Valor Total da Compra: </strong>
            <span>{formatCurrency(salesData?.total)}</span>
          </div>

          <div>
            <strong>Método de Pagamento: </strong>
            <span>{parsePaymentMethod(salesData?.payment_method)}</span>
          </div>
          <div>
            <strong>Prazo de Reembolso até: </strong>
            <span>{refundEndDate(salesData)}</span>
          </div>
          <div>
            <Tooltip
              title={refundAllowed(salesData) ? "Solicitar reembolso" : "Já terminou o prazo de reembolso."}
              placement="top"
            >
              <Button
                disabled={!refundAllowed(salesData)}
                style={{ padding: 0, margin: 0, lineHeight: "17px", height: "auto" }}
                type="link"
                onClick={refundRequest}
              >
                Soliticar reembolso
              </Button>
            </Tooltip>
          </div>
          {cancelFuturePaymentsAllowed(salesData) && (
            <div>
              <Tooltip title="Cancelar pagamentos futuros" placement="top">
                <Button
                  style={{ padding: 0, margin: 0, lineHeight: "17px", height: "auto" }}
                  type="link"
                  onClick={cancelFuturePaymentys}
                >
                  Cancelar pagamentos futuros
                </Button>
              </Tooltip>
            </div>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default PurchaseDetails;
