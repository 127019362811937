import { Link } from "react-router-dom";

const Company = () => {
  return (
    <span className="company">
      ©{new Date().getFullYear()}{" "}
      <Link to={"https://salespark.io/?utm_source=footer"} target="_blank">
        SalesPark Lda
      </Link>
      . Todos os direitos reservados. Os direitos das várias marcas comerciais, são detidos pelos seus respectivos
      proprietários.
    </span>
  );
};

export default Company;
