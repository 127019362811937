import { Select } from "antd";
const { Option } = Select;

export const DoubleDataConfirmationContent = () => (
  <span>
    <span>
      Confirma se todos os dados estão corretos para a emissão da fatura. Relembramos que não é possível alterar os
      dados após a emissão da fatura.{" "}
    </span>
    <br />
    <span>
      <strong>Confirmas que os dados introduzidos estão corretos?</strong>
    </span>
  </span>
);

export const NoVatIDContent = () => (
  <span>
    Não foi indicado o número de contribuinte.
    <br />
    A fatura será emitida com os teus dados, mas com o contribuinte de consumidor final.
    <br />
    <br />
    <strong>Tens a certeza que queres continuar?</strong>
    <br />
    <small>
      <span style={{ textDecoration: "underline" }}>
        Não é possível alterar o contribuinte após a emissão da fatura
      </span>
    </small>
  </span>
);

export const BillingDataFields = ({ fieldTitle, fieldValue }) => {
  return (
    <div className="billing-info-line">
      <div className="billing-info-title">{fieldTitle}:</div>
      <div className="billing-info-value">
        {fieldValue || <span className="billing-info-no-value">Não indicado</span>}
      </div>
    </div>
  );
};

// export const GetCountriesList = ({ countries, onChange }) => {
//   try {
//     if (!countries) return <></>;
//     if (countries.length === 0) return <></>;
//     const Options = countries.map((country) => (
//       <Option key={country.value} value={country.value}>
//         {country.portugueseName} ({country.localName})
//       </Option>
//     ));
//     return <Select onChange={onChange}>{Options}</Select>;
//   } catch (error) {
//     return <></>;
//   }
// };

export const GetRegionsList = ({ countries, country }) => {
  if (country === undefined || country === null) return <></>;
  if (!countries) return <></>;
  if (countries.length === 0) return <></>;
  const regions = countries.find((c) => c.value === country).regions;
  //setRegions(regions);

  return regions.map((region) => (
    <Option key={region.value} value={region.value}>
      {region.name}
    </Option>
  ));
};

const cw = (string) => {
  return string.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const parseCompanyData = (form, data) => {
  try {
    const _address = data.address.split(", ");
    form.setFieldsValue({ name: cw(data.name) });
    form.setFieldsValue({ address: cw(_address[0]) });
    form.setFieldsValue({ city: cw(_address[1]) });
    form.setFieldsValue({ postal_code: _address[2].split(" ")[0] });
  } catch (error) {}
};
