export const cancelationReasons = [
  {
    id: 1,
    name: "Não recebi o produto",
    help: "Verifica se o e-mail com a informação de acesso ao produto está na caixa de Spam ou Promoções. Alguns gestores de e-mail colocam automaticamente novos e-mails nas caixa de Spam e Promoções.",
    ask_details: false,
  },
  {
    id: 2,
    name: "Produto incompleto",
    help: "Alguns produtos estão configurados para o conteúdo ser disponibilizado ao longo do tempo e não ficam completamente disponíveis após a compra. Em caso de dúvida entra em contacto com o produtor.",
    ask_details: true,
  },
  { id: 3, name: "Produto muito confuso de entender", help: undefined, ask_details: true },

  { id: 4, name: "Dificuldades técnicas ao utilizar o produto", help: undefined, ask_details: true },
  { id: 5, name: "Produto não atendeu às minhas necessidades específicas", help: undefined, ask_details: true },
  {
    id: 6,
    name: "Falta de acesso ao produto após a compra",
    help: "Alguns produtos estão configurados para o conteúdo ser disponibilizado ao longo do tempo e não ficam completamente disponíveis após a compra. Em caso de dúvida entra em contacto com o produtor.",

    ask_details: true,
  },
  {
    id: 7,
    name: "Comprei o produto acidentalmente ou fiz uma compra duplicada",
    help: undefined,
    ask_details: true,
  },

  { id: 8, name: "Produto diferente do anunciado", help: undefined, ask_details: true },
  { id: 9, name: "Produto não funciona", help: undefined, ask_details: true },
  { id: 10, name: "Produto não corresponde à descrição", help: undefined, ask_details: true },
  { id: 11, name: "Produto não corresponde à imagem", help: undefined, ask_details: true },
  { id: 12, name: "Produto não corresponde à descrição e imagem", help: undefined, ask_details: true },
  { id: 13, name: "Produto não corresponde às expetativas", help: undefined, ask_details: true },

  { id: 14, name: "Produto com defeito", help: undefined, ask_details: true },
  { id: 15, name: "Outro", help: undefined, ask_details: true },
  { id: 16, name: "Não quero responder", help: undefined, ask_details: false },
];
