import React from "react";

const BannerHeader = ({ data }) => {
  if (!data) return <></>;
  if (!data?.banner) return <></>;
  return (
    <div
      className="top-banner"
      style={{
        background: `url("${data.banner}") center center / cover no-repeat`,
      }}
    ></div>
  );
};

export default BannerHeader;
