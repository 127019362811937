import React from "react";
import { Button, Result } from "antd";

const ProductUnavailable = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 25 }}>
        <img className="img-fluid" src={"/img/logo-white.png"} alt="" />
      </div>
      <Result
        status="404"
        title="Este produto não se encontra disponível de momento."
        subTitle="Entra em contacto com o produtor para mais informações."
        extra={
          <div>
            <Button
              type="primary"
              onClick={() => {
                window.location.reload();
              }}
            >
              Tentar novamente
            </Button>
          </div>
        }
      />
    </>
  );
};

export default ProductUnavailable;
