import React, { useState, useEffect } from "react";
import { Spin, Layout, Card, Skeleton, Button, Modal, notification, Row, Col, Result, Collapse } from "antd";
import SalesParkLogo from "../../assets/images/logo-white-sm.png";
import { DoubleRightOutlined } from "@ant-design/icons";
//import Countdown from "react-countdown";
import { sales_get, sales_billing_data_post } from "../../api/sales";
import BillingForm from "./BillingForm";
import Documents from "./Documents";
import ProductContent from "./ProductContent";
import AppVersion from "../../Components/Shared/AppVersion";
//import moment from "moment-timezone";
//import { countdownRenderer } from "./IndexHelper";
import { useParams, useSearchParams } from "react-router-dom";
import DetailsDrawer from "./DetailsDrawer";
import RefundRequest from "./RefundRequest";
import CancelFuturePayments from "./CancelFuturePayments";
import "../../assets/css/billing.css";
import "../../assets/css/product_content.css";

// import "antd/dist/antd.css";
// import "antd-country-phone-input/dist/index.css";

const { Header, Content } = Layout;
const { confirm } = Modal;

const BillingInformation = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [buyerData, setBuyerData] = useState({});
  const [salesData, setSalesData] = useState([]);
  const [billingData, setBillingData] = useState({});
  const [errorLoadingData, setErrorLoadingData] = useState(false);
  //const [tokenExpired, setTokenExpired] = useState(false);
  const [management, setManagement] = useState("internal");

  const [refundRequestModal, setRefundRequestModal] = useState(false);
  const [cancelFuturePaymentsModal, setCancelFuturePaymentsModal] = useState(false);
  const [showRefundSuccessModal, setRefundShowSuccessModal] = useState(false);
  const [showCancelFuturePaymentsSuccessModal, setCancelFuturePaymentsShowSuccessModal] = useState(false);

  const [showDetailsDrawer, setShowDetailsDrawer] = useState({ visible: false, data: null, billing: null });
  let { purchase_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const TOKEN = searchParams.get("token");
  const ACTION = searchParams.get("action");
  const PURCHASE_ID = purchase_id;

  useEffect(() => {
    if (!dataLoaded) {
      getSaleData(PURCHASE_ID, TOKEN);
    }
  }, []);

  const onConfirmBillingData = async (values) => {
    setIsLoading(true);
    const response = await updateBillingData(values);
    if (response.status) {
      setDataLoaded(false);
      getSaleData(PURCHASE_ID, TOKEN);
    } else {
      notification.error({
        message: "Oops!",
        description: "Não foi possível atualizar os dados de faturação. Verifica os dados, e tenta novamente.",
        duration: 7,
      });
    }

    setIsLoading(false);
  };

  const gotoProductAccessWithoutBilling = () => {
    confirm({
      title: "Ir para o conteúdo do produto",
      content: (
        <>
          Queres ir para o conteúdo do produto sem preencher os dados de faturação?
          <br />
          <br />
          <small>
            <em>Não será possível alterar os dados de faturação posteriormente.</em>
          </small>
        </>
      ),
      okText: "Sim, ir para o conteúdo",
      okType: "primary",
      cancelType: "default",
      cancelText: "Não",
      keyboard: true,
      maskClosable: true,
      onOk() {
        const values = billingData || buyerData;
        values.billing_ignored = true;
        onConfirmBillingData(values);
      },
      onCancel() {
        console.log("Cancelar");
      },
    });
  };

  const updateBillingData = (data) => {
    return new Promise((resolve) => {
      data.token = TOKEN;
      data.purchase_id = PURCHASE_ID;
      try {
        sales_billing_data_post(data)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  // const updateBuyerData = (data) => {
  //   return new Promise((resolve) => {
  //     data.token = TOKEN;
  //     data.purchase_id = PURCHASE_ID;
  //     try {
  //       sales_buyer_data_post(data)
  //         .then((res) => resolve({ status: true, data: res.data }))
  //         .catch((err) => resolve({ status: false, data: err }));
  //     } catch (error) {
  //       resolve({ status: false, data: error });
  //     }
  //   });
  // };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const getSaleData = async (purchase_id, token) => {
    try {
      setIsLoading(true);

      let counter = 0;
      let response = null;

      do {
        response = await dbGetSaleData(purchase_id, token);
        counter++;
        await delay(2500);
      } while (response.status === false && counter < 10);

      if (!response.status) {
        // if (response.data?.response?.data?.errorCode === 99) {
        //setTokenExpired(true);
        window.location = `/authentication/?purchase_id=${PURCHASE_ID}`;
        return;
        //  }
        // setErrorLoadingData(true);
        // setIsLoading(false);
        // setDataLoaded(true);
        // return;
      }

      if (response.data === "") {
        window.location = `${window.location.origin}/authentication`;
        return;
      }

      setSalesData(response.data.sale);
      setBuyerData(response.data.buyer);
      setBillingData(response.data.billing);
      setManagement(response.data?.management || "internal");
      setIsLoading(false);
      setDataLoaded(true);

      console.log("response.data.sale", response.data);

      if (ACTION === "refund") {
        setRefundRequestModal(true);
        if (searchParams.has("action")) {
          searchParams.delete("action");
          setSearchParams(searchParams);
        }
      }

      // Error handling
    } catch (error) {
      setErrorLoadingData(true);
      setIsLoading(false);
      setDataLoaded(true);
    }
  };

  const dbGetSaleData = (purchase_id, token) => {
    return new Promise((resolve) => {
      try {
        sales_get(purchase_id, token)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const grettings = () => {
    const name = buyerData?.name ?? "";
    if (salesData?.billing_data_completed !== true) {
      return `Parabéns ${name} 🤗`;
    } else {
      return `Olá ${name} 👋`;
    }
  };

  return (
    <>
      <Spin spinning={isLoading || !dataLoaded ? true : false} size="large" tip="A carregar dados...">
        <div className="flex-container">
          <Layout className="flex-box">
            <Header style={{ padding: 0, height: 64 }} className="salespark-logo">
              <img height={15} src={SalesParkLogo} alt="SalesPark" />
            </Header>
            <Layout style={{ backgroundColor: "white" }}>
              <Content className="main-container billing-container">
                <>
                  {(isLoading || !dataLoaded) && (
                    <>
                      <Skeleton active />
                      <Skeleton active />
                      <Skeleton active />
                      <Skeleton active />
                      <Skeleton active />
                      <Skeleton active />
                    </>
                  )}
                  {/* {dataLoaded && errorLoadingData && (
                    <Card>
                      <Result
                        status="error"
                        title="Oops! Algo errado não está bem..."
                        subTitle="Não foi possível carregar os dados da tua compra. Tenta novamente mais tarde."
                        extra={[
                          <Button
                            type="link"
                            href="https://salespark.io/redirect/?u=central-ajuda"
                            target={"_blank"}
                            key="support"
                          >
                            Ir para a Central de Ajuda
                          </Button>,
                          <Button
                            type="primary"
                            key="try-again"
                            href={`/authentication/?purchase_id=${PURCHASE_ID}`}
                          >
                            Tentar novamente
                          </Button>,
                        ]}
                      ></Result>
                    </Card>
                  )} */}
                  {/* {dataLoaded && tokenExpired && errorLoadingData && (
                    <Card>
                      <Result
                        status="warning"
                        title="Oops! O link já expirou..."
                        subTitle="O link do e-mail já expirou. Mas não te preocupes, basta clicares no botão abaixo para te autenticares."
                        extra={[
                          <Button
                            type="primary"
                            key="try-again"
                            href={`/authentication/?purchase_id=${PURCHASE_ID}`}
                          >
                            Autenticar
                          </Button>,
                        ]}
                      ></Result>
                    </Card>
                  )} */}
                  {dataLoaded && !errorLoadingData && (
                    <>
                      <Card className="billing-purchase-card">
                        <Row gutter={20} justify="center">
                          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            <div className="purchase-info">
                              {salesData?.billing_data_completed !== true ? (
                                <>
                                  <h3 className="section-title">{grettings()}</h3>
                                  <h3 className="section-sub-title">A tua compra foi concluída com sucesso!</h3>
                                </>
                              ) : (
                                <>
                                  <h3 className="section-title">{grettings()}</h3>
                                  <h3 className="section-sub-title">O conteúdo do produto está abaixo.</h3>
                                </>
                              )}
                              <div className="view-purchase-details-top">
                                <Button
                                  type="link"
                                  style={{ padding: 0, margin: 0, height: "auto" }}
                                  onClick={() =>
                                    setShowDetailsDrawer({ visible: true, data: null, billing: billingData })
                                  }
                                >
                                  Ver Detalhes da Compra
                                </Button>
                              </div>
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            {salesData?.billing_data_completed !== true && (
                              <div className="product-access">
                                <Button
                                  type="primary"
                                  size="large"
                                  className="btn-product-access"
                                  onClick={() => gotoProductAccessWithoutBilling()}
                                >
                                  Ir para o conteúdo do produto <DoubleRightOutlined />
                                </Button>
                              </div>
                            )}
                            <div className="view-purchase-details-bottom">
                              <Button
                                type="link"
                                style={{ padding: 0, margin: 0, height: "auto" }}
                                onClick={() =>
                                  setShowDetailsDrawer({ visible: true, data: null, billing: billingData })
                                }
                              >
                                Ver Detalhes da Compra
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                      {salesData?.billing_data_completed !== true && (
                        <BillingForm
                          billing={billingData}
                          sale={salesData}
                          buyer={buyerData}
                          onConfirmBillingData={onConfirmBillingData}
                        />
                      )}

                      {salesData?.billing_data_completed && (
                        <>
                          <ProductContent sale={salesData} token={TOKEN} />
                          <Documents sale={salesData} token={TOKEN} management={management} />
                        </>
                      )}
                    </>
                  )}

                  <div style={{ textAlign: "center", padding: 8, paddingTop: 20 }}>
                    <AppVersion />
                  </div>
                </>
              </Content>
            </Layout>
          </Layout>
        </div>
      </Spin>

      <DetailsDrawer
        visible={showDetailsDrawer.visible}
        billing={showDetailsDrawer.billing}
        buyerData={buyerData}
        salesData={salesData}
        TOKEN={TOKEN}
        ACTION={ACTION}
        refundRequest={() => setRefundRequestModal(true)}
        cancelFuturePaymentys={() => setCancelFuturePaymentsModal(true)}
        close={() => setShowDetailsDrawer({ visible: false, data: null, billing: null })}
      />
      <RefundRequest
        visible={refundRequestModal}
        close={(e) => {
          setRefundRequestModal(false);
          if (e === true) {
            setRefundShowSuccessModal(true);
          }
        }}
        sale={salesData}
        billing={billingData}
        buyer={buyerData}
        refundEndDate={salesData?.refund_end_date}
        token={TOKEN}
      />
      <CancelFuturePayments
        visible={cancelFuturePaymentsModal}
        close={(e) => {
          setCancelFuturePaymentsModal(false);
          if (e === true) {
            setCancelFuturePaymentsShowSuccessModal(true);
          }
        }}
        sale={salesData}
        token={TOKEN}
      />
      <Modal
        width={700}
        destroyOnClose
        closable={false}
        title="Pedido de reembolso efetuado com sucesso"
        visible={showRefundSuccessModal}
        footer={null}
        // onCancel={() => setShowSuccessModal(false)}
      >
        <Result
          status="success"
          title="O teu pedido de reembolso foi solicitado com sucesso!"
          subTitle="O processo de reembolso demora apenas aguns dias. Não é necessário fazer mais nada."
          extra={[
            <Button type="primary" key="ack1" onClick={() => setRefundShowSuccessModal(false)}>
              Compreendi
            </Button>,
          ]}
        />
      </Modal>
      <Modal
        width={700}
        destroyOnClose
        closable={false}
        title="Pagamentos futuros cancelados com sucesso"
        visible={showCancelFuturePaymentsSuccessModal}
        footer={null}
        // onCancel={() => setShowSuccessModal(false)}
      >
        <Result
          status="success"
          title="Pagamentos futuros cancelados com sucesso!"
          subTitle="Os pagamentos futuros foram cancelados com sucesso."
          extra={[
            <Button type="primary" key="ack2" onClick={() => setCancelFuturePaymentsShowSuccessModal(false)}>
              Ok
            </Button>,
          ]}
        />
      </Modal>
    </>
  );
};

export default BillingInformation;
