import React, { useState, useEffect } from "react";
import { Modal, Form, Select, Spin, Tag, Checkbox, notification, Alert, Input } from "antd";
import { sales_basic_get, sales_cancel_future_payments_post } from "api/sales";
import { formatCurrency } from "utils/helper";
import { cancelationReasons } from "./helper";

const { Option } = Select;
const { confirm } = Modal;

const CanceledInfo = ({ data }) => {
  if (data?.canceled === true) {
    return <Tag color="red">Pagamentos futuros já cancelados.</Tag>;
  }

  return <></>;
};

const CancelFuturePayments = ({ visible, close, sale, token }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState({ visible: false, label: "Carregando..." });
  const [sales, setSales] = useState([]);
  const [selectedReason, setSelectedReason] = useState(undefined);
  const [cancelationConfirmed, setCancelationConfirmed] = useState(false);

  const selectedReasonItem = cancelationReasons?.find((item) => item?.id === selectedReason);

  useEffect(() => {
    if (visible) {
      setCancelationConfirmed(false);
      loadData(sale?.purchase_id);
    }
  }, [visible]);

  const loadData = async (purchase_id) => {
    setIsLoading({ visible: true, label: "A carregar dados da compra..." });
    const response = await dbGetSaleBasicData(purchase_id, token);
    if (!response.status) {
      notification.error({
        message: "Oops!",
        description: "Não foi possível carregar os dados da compra. Tenta novamente mais tarde.",
      });
      setIsLoading({ visible: false, label: "Aguarda" });
      close();
      return;
    }
    setSales(response.data);
    setIsLoading({ visible: false, label: "Aguarda" });
  };

  const dbGetSaleBasicData = (purchase_id, token) => {
    return new Promise((resolve) => {
      try {
        sales_basic_get(purchase_id, token)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const dbPostCancelFuturePayments = (purchase_id, token, data) => {
    return new Promise((resolve) => {
      try {
        sales_cancel_future_payments_post(purchase_id, token, data)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const onFinish = async (values) => {
    values.reason = selectedReasonItem.name;

    const productName = sales?.find((item) => item?._id === values?.product)?.product_name;

    confirm({
      title: "Tens mesmo a certeza?",
      content: (
        <>
          Tens mesmo a certeza que pretendes cancelar os pagamentos futuros do produto <b>{productName}</b>?
        </>
      ),
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        cancelFuturePaymentsAction(values);
      },
      onCancel() {
        close(false);
      },
    });
  };

  const cancelFuturePaymentsAction = async (values) => {
    setIsLoading({ visible: true, label: "A enviar pedido de cancelamento de pagamentos futuros. Aguarda..." });

    // Post refund request
    const response = await dbPostCancelFuturePayments(sale?.purchase_id, token, values);
    // Check if request was successful
    if (!response.status) {
      notification.error({
        message: "Oops!",
        description: "Não foi possível cancelar os pagamentos futuros. Tenta novamente mais tarde.",
      });
      setIsLoading({ visible: false, label: "Aguarda" });

      return;
    }

    notification.success({
      message: "Sucesso!",
      description: "Os pagamentos futuros foram cancelados com sucesso.",
    });

    setIsLoading({ visible: true, label: "Aguarda" });
    close(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const cannotBeCanceled = (item) => {
    if (item?.purchase_type === "recurrence") return true;
    if (item?.refund === true) return true;
    if (item?.canceled === true) return true;
    if (item?.refund_requested === true) return true;
    if (item?.dissalow_refund === true) return true;
    return false;
    //sale?.refund_requested || sale?.dissalow_refund
  };

  return (
    <Modal
      width={"600px"}
      destroyOnClose
      title="Cancelar Pagamentos Futuros"
      visible={visible}
      okButtonProps={{
        form: "frmCancelFuturePayments",
        type: "primary",
        htmlType: "submit",
        key: "modal-submit",
      }}
      okText="Confirmar"
      cancelText="Cancelar"
      onCancel={close}
    >
      <Spin spinning={isLoading.visible} tip={isLoading.label}>
        <Form
          id="frmCancelFuturePayments"
          // labelCol={{ span: 24 }}
          // wrapperCol={{ span: 24 }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="refundRequestForm"
        >
          <Form.Item
            name="product"
            label="Produto"
            className="fixedFormItem"
            rules={[
              {
                required: true,
                message: "Campo de preenchimento obrigatório.",
              },
            ]}
            hasFeedback
          >
            <Select>
              {sales
                .filter((el) => el.purchase_type === "recurrence")
                ?.map((sale) => (
                  <Option key={sale._id} value={sale._id} disabled={!cannotBeCanceled(sale)}>
                    {sale.product_name} <small style={{ color: "#72849a" }}>({formatCurrency(sale.total)})</small>{" "}
                    <CanceledInfo data={sale} />
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="reason"
            label="Motivo"
            className="fixedFormItem"
            rules={[
              {
                required: true,
                message: "Campo de preenchimento obrigatório.",
              },
            ]}
            hasFeedback
          >
            <Select onChange={(e) => setSelectedReason(e)}>
              {cancelationReasons.map((reason) => (
                <Option key={reason.id} value={reason.id}>
                  {reason.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {selectedReasonItem?.help !== undefined && (
            <Form.Item>
              <Alert message={selectedReasonItem.help} type="info" showIcon />
            </Form.Item>
          )}
          {selectedReasonItem?.ask_details && (
            <Form.Item name="details" label="Detalhes" className="fixedFormItem">
              <Input.TextArea rows={4} placeholder="Explica mais detalhadamente o teu motivo." />
            </Form.Item>
          )}

          <Form.Item
            name="confirm"
            valuePropName="checked"
            rules={[
              {
                required: true,
                transform: (value) => value || undefined, // Those two lines
                type: "boolean", // Do the magic
                message: "Confirmação é obrigatória.",
              },
            ]}
            hasFeedback
          >
            <Checkbox onChange={(e) => setCancelationConfirmed(e.target.checked)}>
              Confirmo que pretendo cancelar os pagamentos futuros.
            </Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CancelFuturePayments;
