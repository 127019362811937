import React from "react";
import { Link } from "react-router-dom";
import { Card } from "antd";
const Powered = () => {
  return (
    <Card size="small" className="card-powered no-radius-bottom">
      <div className="card-powered-content">
        Powered by{" "}
        <Link to={"https://salespark.io/"} target="_blank">
          SalesPark
        </Link>
      </div>
    </Card>
  );
};

export default Powered;
