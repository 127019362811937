import React, { useState, useEffect } from "react";
import { Card, Button, Typography, Divider } from "antd";
import { formatCurrency, IsNullUndefinedEmpty } from "../../../utils/helper";
import OrderBump from "../../Shared/OrderBump";


const OrderBumpComponent = ({ step, orderBumps, data, actionHandler }) => {
  if (IsNullUndefinedEmpty(orderBumps)) return <></>;

  return orderBumps.map((orderBump) => {
    return (
      <div key={orderBump.product.product_id}>
        <OrderBump step={step} orderBump={orderBump} data={data} actionHandler={actionHandler} />
      </div>
    );
  });
};

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 **********************************************************************************************/
const OrderBumps = ({ productData, data, actionHandler, step }) => {
  return (
    <OrderBumpComponent orderBumps={productData.bumps} data={data} step={step} actionHandler={actionHandler} />
  );
};

export default OrderBumps;
