import React from "react";
import { Button, Card } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const Quantity = ({ data, actionHandler, isMobile }) => {
  
  if (data?.format !== "online-event" && data?.format !== "physical-event") return <></>;

  const statusAction = data?.status?.action;
  const isDisabled = statusAction === "paid" || statusAction === "wait-for-payment";

  const minQty = 1;
  const maxQty = 10;
  let qty = data.qty || 1;
  const updateQty = (action) => {
    if (action === "add") qty++;
    if (action === "subtract") qty--;

    if (qty < 1) qty = 1;

    actionHandler("qty", qty);
  };
  return (
    <Card size="small" title={<div style={{ textAlign: "center" }}>Quantidade</div>} type="inner">
      <div className="qty-container">
        {!isDisabled && (
          <Button
            type="default"
            shape="circle"
            onClick={() => updateQty("subtract")}
            disabled={qty <= minQty || isDisabled}
          >
            <MinusOutlined />
          </Button>
        )}
        <div className="qty-indicator">
          {qty} {isDisabled && <span>Unidades</span>}
        </div>
        {!isDisabled && (
          <Button
            type="default"
            shape="circle"
            onClick={() => updateQty("add")}
            disabled={qty >= maxQty || isDisabled}
          >
            <PlusOutlined />
          </Button>
        )}
      </div>
    </Card>
  );
};

export default Quantity;
